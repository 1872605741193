import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../myAxios";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { Form, Input, Button, message, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./EditAdmin.css";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const EditAdmin = () => {
  const [user, setUser] = useContext(UserContext);
  const [province, setProvince] = useState(null);
  const [dataDetail, setDataDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const array = [];
  let history = useHistory();
  const { userId } = useParams();
  useEffect(() => {
    if (dataDetail === null) {
      getDataAdmin();
    }
  });

  function handleChange(value) {
    console.log(`Selected: ${value}`);
  }

  const getDataAdmin = () => {
    myAxios
      .get(`adminEdit/${userId}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        for (let i = 0; i < data.provinsi.length; i++) {
          array.push(data.provinsi[i].provinsi);
        }

        form.setFieldsValue({
          nama: data.name,
          provinsi: array,
          email: data.email,
          status: data.status,
        });
        setProvince(data.kumpulanProvinsi);
        setDataDetail(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Tidak Ditemukan") {
            message.error(err.response.data.message);
            history.push(`/tabelPenelitian`);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    if (values.password === undefined) values.password = "-";
    let newObj = {
      id: userId,
      name: values.nama,
      email: values.email,
      status: values.status,
      provinsi: values.provinsi,
      password: values.password,
    };

    myAxios
      .put(`editAdmin`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Admin berhasil di edit!");
        history.push("/lihatAdmin");
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }

        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div
      className="profil"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Edit Admin</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {!dataDetail && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {dataDetail && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            labelAlign="left"
            label="Nama"
            name="nama"
            rules={[
              {
                required: true,
                message: "Bagian ini wajib diisi",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Masukan email yang valid",
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          {province && (
            <Form.Item
              labelAlign="left"
              label="Wilayah"
              name="provinsi"
              rules={[
                {
                  required: true,
                  message: "Bagian ini wajib diisi",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Silahkan pilih wilayah"
                onChange={handleChange}
                style={{ width: "100%" }}
              >
                {province.map((val, item) => (
                  <Option key={val.name}>{val.name}</Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            labelAlign="left"
            label="Kata Sandi"
            name="password"
            rules={[
              {
                min: 6,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item labelAlign="left" label="Status" name="status">
            <Select>
              <Select.Option value="Aktif">Aktif</Select.Option>
              <Select.Option value="Tidak Aktif">Tidak Aktif</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={() => form.resetFields()}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditAdmin;
