import React from "react";
import myAxios from "../../myAxios";
import moment from "moment";
import "./TabelPengabdian.css";
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TrashFill } from "react-bootstrap-icons";
import { UserContext } from "../../context/UserContext.jsx";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class TabelPengabdian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchedColumn: "",
      loading: false,
      daftarPengabdian: null,
      tempdaftarPengabdian: null,
      visible: false,
      dataDetail: null,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarPengabdian === null) {
      this.getPengabdian();
    }
  }

  getPengabdian = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`pengabdianAdmin`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          data.map((el) => {
            if (el.budget !== "-") {
              el.budgetNumber = new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(el.budget);

              el.budgetFilter = Number(el.budget);
            } else el.budgetNumber = "-";

            if (el.actual !== "-") {
              el.actualNumber = new Intl.NumberFormat("id-ID", {
                style: "currency",
                currency: "IDR",
              }).format(el.actual);
              el.actualFilter = Number(el.actual);
            } else el.actualNumber = "-";
            el.tanggal_mulai = moment(el.tanggal_mulai).format("LL");
          });

          data.map((el) => {
            el.id_parsing = `${el.id_abmas}/${el.id_biaya}`;
          });

          this.setState({
            daftarPengabdian: data,
            tempdaftarPengabdian: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarPengabdian: null,
            tempdaftarPengabdian: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearch = (evt) => {
    if (this.state.tempdaftarPengabdian) {
      if (evt.target.value === "") {
        this.setState({ daftarPengabdian: this.state.tempdaftarPengabdian });
      } else {
        this.setState({
          daftarPengabdian: this.state.tempdaftarPengabdian.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.budget.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.actual.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.nama_provinsi
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.tanggal_mulai
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.topik.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.sumber_biaya
                .toLowerCase()
                .includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  onTerima = (param) => {
    const user = this.context[0];
    myAxios
      .put(`confirmDosen/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        let filter = this.state.daftarPengabdian.filter((el) => {
          return el.id !== param;
        });
        this.setState({
          daftarPengabdian: filter,
          tempdaftarPengabdian: filter,
        });

        message.success("Dosen berhasil diterima!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onDelete = (param) => {
    const user = this.context[0];
    myAxios
      .put(`deletePengabdianAdmin/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        this.getPengabdian();
        message.success("Pengabdian berhasil di hapus!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onEditPengabdian = (index) => {
    window.location.pathname = `/editPengabdian/${index}`;
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: "7%",
      },
      {
        title: "Nama Anggota",
        dataIndex: "nama_gelar",
        key: "nama_gelar",
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: "Kota",
        dataIndex: "nama_provinsi",
        key: "nama_provinsi",
        filteredValue: filteredInfo.nama_provinsi || null,
        sorter: (a, b) => a.nama_provinsi.length - b.nama_provinsi.length,
        ellipsis: true,
      },
      {
        title: "Pengabdian Mulai",
        dataIndex: "tanggal_mulai",
        key: "tanggal_mulai",
        filteredValue: filteredInfo.tanggal_mulai || null,
        sorter: (a, b) => a.tanggal_mulai.length - b.tanggal_mulai.length,
        ellipsis: true,
      },

      {
        title: "Topik",
        dataIndex: "topik",
        key: "topik",
        filteredValue: filteredInfo.topik || null,
        sorter: (a, b) => a.topik.length - b.topik.length,
        ellipsis: true,
      },
      {
        title: "Sumber Biaya",
        dataIndex: "sumber_biaya",
        key: "sumber_biaya",
        filteredValue: filteredInfo.sumber_biaya || null,
        sorter: (a, b) => a.sumber_biaya.length - b.sumber_biaya.length,
        ellipsis: true,
      },
      {
        title: "Budget",
        dataIndex: "budgetNumber",
        key: "budget",
        filteredValue: filteredInfo.budgetFilter || null,
        sorter: (a, b) => a.budgetFilter - b.budgetFilter,
        ellipsis: true,
      },
      {
        title: "Actual",
        dataIndex: "actualNumber",
        key: "actual",
        filteredValue: filteredInfo.actualFilter || null,
        sorter: (a, b) => a.actualFilter - b.actualFilter,
        ellipsis: true,
      },
      {
        align: "center",
        title: "Aksi",
        dataIndex: "id_parsing",
        key: "id",

        render: (dataIndex) => (
          <div className="aksiPengabdian">
            <Row justify="space-around">
              {/* <Col style={{ marginTop: "2px" }}>
                <Tooltip
                  placement="bottom"
                  title="Edit pengabdian"
                  color="#1f1f1f"
                  key="white"
                >
                  <Button
                    className="btn-edit"
                    onClick={() => this.onEditPengabdian(dataIndex)}
                  >
                    <PencilFill />
                  </Button>
                </Tooltip>
              </Col> */}
              <Col style={{ marginTop: "2px" }}>
                <Tooltip
                  placement="bottom"
                  title="Hapus pengabdian"
                  color="#1f1f1f"
                  key="white"
                >
                  <Popconfirm
                    placement="left"
                    title={"Hapus data pengabdian ?"}
                    onConfirm={() => this.onDelete(dataIndex)}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button className="btn-tolak">
                      <TrashFill />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <div
        className="pengabdian"
        style={{
          padding: "25px 30px",
          margin: "25px 30px",
          backgroundColor: "white",
        }}
      >
        <h1
          style={{
            fontSize: "x-large",
            color: "#001529",
            textTransform: "uppercase",
          }}
        >
          <strong>Tabel Pengabdian</strong>
        </h1>
        <div
          style={{
            border: "1px solid #8C98AD",
            marginTop: "-10px",
            marginBottom: "15px",
          }}
        ></div>
        <Row style={{ marginBottom: 16 }} justify="space-between">
          <Col md={12}>
            <Button className="btn-hapus-filter" onClick={this.clearFilters}>
              Hapus Filter
            </Button>
          </Col>
          <Col md={12}>
            <Input
              style={{ width: "100%" }}
              placeholder="Cari data pengabdian disini"
              onChange={this.onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          scroll={{ x: 900, y: 1500 }}
          columns={columns}
          dataSource={this.state.daftarPengabdian}
          onChange={this.handleChange}
          size="middle"
        />
      </div>
    );
  }
}

export default TabelPengabdian;
