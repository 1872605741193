import React, { useState, useEffect } from "react";
import myAxios from "../../myAxios";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Image,
  DatePicker,
  Row,
  Col,
} from "antd";

import moment from "moment";
import "./Registrasi.css";
import DefImg from "../../assets/img/defImg.png";

const { Option } = Select;
const Registrasi = () => {
  const [loading, setLoading] = useState(false);
  const [passwordTemp, setPasswordTemp] = useState(null);
  const [form] = Form.useForm();
  const [institusi, setInstitusi] = useState(null);
  const [province, setProvince] = useState(null);
  const [img, setImg] = useState(null);
  const [imgApi, setImgApi] = useState(null);

  useEffect(() => {
    if (institusi === null && province === null) {
      getInstitusiRegist();
    }
  });

  const getInstitusiRegist = () => {
    myAxios
      .get(`institusiRegist`)
      .then((res) => {
        const data = res.data;
        setInstitusi(data.institusi);
        setProvince(data.provinsi);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("nama_lengkap", values.nama_lengkap);
    if (values.gelar_depan !== undefined && values.gelar_depan !== "") {
      formData.append("gelar_depan", values.gelar_depan);
    }

    if (values.gelar_belakang !== undefined && values.gelar_belakang !== "")
      formData.append("gelar_belakang", values.gelar_belakang);

    if (values.jenjang !== undefined) formData.append("jenjang", values.jenjang);

    if (values.pendidikan !== undefined) formData.append("pendidikan", values.pendidikan);

    if (values.jenjang_akademik !== undefined) formData.append("jenjang_akademik", values.jenjang_akademik);

    if (values.nidn !== undefined) formData.append("nidn", values.nidn);

    if (values.keahlian_1 !== undefined && values.keahlian_1 !== "")
     formData.append("keahlian_1", values.keahlian_1);

    if (values.keahlian_2 !== undefined && values.keahlian_2 !== "") formData.append("keahlian_2", values.keahlian_2);

    if (values.keahlian_3 !== undefined && values.keahlian_3 !== "") formData.append("keahlian_3", values.keahlian_3);

    formData.append("tempat_lahir", values.tempat_lahir);
    formData.append("tanggal_lahir", values.tanggal_lahir);
    formData.append("id_institusi", values.id_institusi);
    formData.append("provinsi_institusi", values.provinsi_institusi);
    formData.append("prodi", values.prodi);
    formData.append("no_hp", values.no_hp);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("foto", imgApi);

    myAxios
      .post(`registrasi`, formData)
      .then((res) => {
        message.success("Registrasi berhasil!");
        setLoading(false);
        window.location.pathname = `/login`;
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImg(URL.createObjectURL(img));
      setImgApi(img);
      form.setFieldsValue({
        foto: img,
      });
    }
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onReset = () => {
    form.resetFields();
    setImg(null);
  };

  const hpValidation = async (rule, value, callback) => {
    if (value === "" || value === undefined) {
      rule.message = "Bagian ini wajib diisi";
      form.setFields({
        no_hp: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else if (value < 0) {
      rule.message = "Nomor Telepon tidak valid!";
      form.setFields({
        no_hp: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else {
      await callback();
    }
  };

  const onChangeTanggalLahir = (evt) => {};

  const onChangePassword = (evt) => {
    setPasswordTemp(evt.target.value);
  };

  const confirmPassword = async (rule, value, callback) => {
    if (value === "" || value === undefined) {
      rule.message = "Bagian ini wajib diisi";
      form.setFields({
        reNewPass: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else if (value !== passwordTemp) {
      rule.message = "Kata sandi tidak cocok!";
      form.setFields({
        reNewPass: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else {
      await callback();
    }
  };

  const nidnValidator = async (rule, value, callback) => {
    if (value !== undefined && value !== "") {
      if (value.length !== 10) {
        rule.message = "NIDN harus 10 digit!";
        form.setFields({
          nidn: {
            value: value,
            errors: [new Error("forbid ha")],
          },
        });
      }
    } else {
      await callback();
    }
  };

  return (
    <div className="registrasi">
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Registrasi Anggota</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      <Form
        layout="vertical"
        form={form}
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          labelAlign="left"
          label="Nama Lengkap"
          name="nama_lengkap"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <Input />
        </Form.Item>
        <Row justify="space-between">
          <Col md={11} xs={11}>
            <Form.Item labelAlign="left" label="Gelar Depan" name="gelar_depan">
              <Input />
            </Form.Item>
          </Col>
          <Col md={11} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Gelar Belakang"
              name="gelar_belakang"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col md={11} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Tempat Lahir"
              name="tempat_lahir"
              rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={11} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Tanggal Lahir"
              name="tanggal_lahir"
              rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder="DD/MM/YYYY"
                format="DD/MM/YYYY"
                onChange={onChangeTanggalLahir}
                disabledDate={(current) => {
                  return current > moment();
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        {institusi && (
          <Form.Item
            labelAlign="left"
            label="Nama Institusi"
            name="id_institusi"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Select
              style={{ width: "100%" }}
              autoComplete="off"
              showSearch
              placeholder="Cari Institusi"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
              //   0
              // }
            >
              {institusi.map((val, item) => (
                <Option
                  key={val.id}
                  value={val.id}
                  style={{ fontWeight: "bold" }}
                >
                  {val.institusi_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {province && (
          <Form.Item
            labelAlign="left"
            label="Provinsi"
            name="provinsi_institusi"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Select
              style={{ width: "100%" }}
              autoComplete="off"
              showSearch
              placeholder="Cari Provinsi"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
              //   0
              // }
            >
              {province.map((val, item) => (
                <Option
                  key={val.id}
                  value={val.name}
                  style={{ fontWeight: "bold" }}
                >
                  {val.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          labelAlign="left"
          label="Program Studi"
          name="prodi"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <Input />
        </Form.Item>
        <Row justify="space-between">
          <Col md={7} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Jenjang Mengajar"
              name="jenjang"
            >
              <Select>
                <Select.Option value="D1">D1</Select.Option>
                <Select.Option value="D2">D2</Select.Option>
                <Select.Option value="D3">D3</Select.Option>
                <Select.Option value="S1">S1</Select.Option>
                <Select.Option value="S2">S2</Select.Option>
                <Select.Option value="S3">S3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={7} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Pendidikan Terakhir"
              name="pendidikan"
            >
              <Select>
                <Select.Option value="S1">S1</Select.Option>
                <Select.Option value="S2">S2</Select.Option>
                <Select.Option value="S3">S3</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col md={7} xs={24}>
            <Form.Item
              labelAlign="left"
              label="Jenjang Akademik"
              name="jenjang_akademik"
            >
              <Select>
                <Select.Option value="Tenaga Pengajar">
                  Tenaga Pengajar
                </Select.Option>
                <Select.Option value="Asisten Ahli">Asisten Ahli</Select.Option>
                <Select.Option value="Lektor">Lektor</Select.Option>
                <Select.Option value="Lektor Kepala">
                  Lektor Kepala
                </Select.Option>
                <Select.Option value="Guru Besar">Guru Besar</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col md={7} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Bidang Keahlian 1"
              name="keahlian_1"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={7} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Bidang Keahlian 2"
              name="keahlian_2"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col md={7} xs={24}>
            <Form.Item
              labelAlign="left"
              label="Bidang Keahlian 3"
              name="keahlian_3"
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          labelAlign="left"
          label="NIDN"
          name="nidn"
          rules={[{ validator: nidnValidator }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Nomor Handphone"
          name="no_hp"
          rules={[{ required: true, validator: hpValidation }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Masukan email yang valid",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row justify="space-between">
          <Col md={11} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Kata Sandi"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Kata sandi minimal terdiri dari 6 karakter!",
                  min: 6,
                },
              ]}
            >
              <Input.Password onChange={onChangePassword} />
            </Form.Item>
          </Col>
          <Col md={11} xs={11}>
            <Form.Item
              labelAlign="left"
              label="Konfirmasi Kata Sandi"
              name="rePass"
              rules={[
                {
                  required: true,
                  validator: confirmPassword,
                  min: 6,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="foto"
          label="Unggah Gambar"
          labelAlign="left"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <input
            style={{ marginBottom: "10px" }}
            type="file"
            name="myImage"
            onChange={onImageChange}
          />
          <Image
            name="foto"
            fallback={DefImg} // default image
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
            src={img}
          />
        </Form.Item>
        <Form.Item>
          <Button
            style={{ width: "fit-content", marginRight: "5px" }}
            // type='primary'
            danger
            onClick={onReset}
            loading={loading}
          >
            Reset
          </Button>
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Registrasi;
