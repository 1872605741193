import React from "react";
import myAxios from "../../myAxios.js";
import { UserContext } from "../../context/UserContext.jsx";
import Highcharts from "highcharts/highstock";
import MapChart from "./Map.jsx";
import mapData from "./data/mapData.js";
import { useState, useContext, useEffect } from "react";
import { message } from "antd";

require("highcharts/indicators/indicators")(Highcharts);
require("highcharts/indicators/pivot-points")(Highcharts);
require("highcharts/indicators/macd")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/map")(Highcharts);

const Choropleth = () => {
  const [dataApi, setDataApi] = useState(null);
  const [user, setUser] = useContext(UserContext);

  useEffect(() => {
    if (!dataApi) getData();
  });

  const getData = () => {
    myAxios
      .get(`map`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setDataApi({
          title: {
            text: "Grafik Sebaran Dosen Berdasarkan Provinsi",
          },
          colorAxis: {
            min: 0,
            stops: [
              [0, "#EFEFFF"],
              [0.67, "#4444FF"],
              [1, "#000022"],
            ],
          },
          tooltip: {},
          series: [
            {
              mapData: mapData,
              dataLabels: {
                formatter: function () {
                  return this.point.properties["woe-label"].split(",")[0];
                },
              },
              name: "Indonesia",
              data: data,
            },
          ],
        });
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
      });
  };

  return (
    <div style={{ width: "100%" }}>
      {dataApi && <MapChart options={dataApi} highcharts={Highcharts} />}
    </div>
  );
};
export default Choropleth;
