import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../myAxios";
import { UserContext } from "../../context/UserContext";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Image,
  DatePicker,
  Row,
  Col,
  Spin,
  Popconfirm,
} from "antd";
import moment from "moment";
import "./EditAnggota.css";
import DefImg from "../../assets/img/defImg.png";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const EditAnggota = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState(null);
  const [img, setImg] = useState(null);
  const [imgApi, setImgApi] = useState(null);
  const [imgFromApi, setImgFromApi] = useState(null);

  const { userId } = useParams();

  let history = useHistory();
  useEffect(() => {
    if (dataDetail === null) {
      getDataAnggota();
    }
  });

  const getDataAnggota = () => {
    myAxios
      .get(`dosen/${userId}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        let nidntemp = undefined;
        let keahlian_1 = undefined;
        let keahlian_2 = undefined;
        let keahlian_3 = undefined;

        if (data.nidn !== "-") {
          nidntemp = data.nidn;
        } else {
          data.nidn = undefined;
        }

        if (data.keahlian_1 !== "-") {
          keahlian_1 = data.keahlian_1;
        } else {
          keahlian_1 = undefined;
        }

        if (data.keahlian_2 !== "-") {
          keahlian_2 = data.keahlian_2;
        } else {
          keahlian_2 = undefined;
        }

        if (data.keahlian_3 !== "-") {
          keahlian_3 = data.keahlian_3;
        } else {
          keahlian_3 = undefined;
        }

        if (data.tanggal_meninggal !== null) {
          data.tanggal_meninggal = moment(data.tanggal_meninggal);
        }
        form.setFieldsValue({
          nama_lengkap: data.nama_lengkap,
          gelar_depan: data.gelar_depan,
          gelar_belakang: data.gelar_belakang,
          tempat_lahir: data.tempat_lahir,
          tanggal_lahir: moment(data.tanggal_lahir),
          institusi_asal: data.institusi_asal,
          kota_institusi: data.kota_institusi,
          provinsi_institusi: data.provinsi_institusi,
          prodi: data.prodi,
          jenjang: data.jenjang,
          pendidikan: data.pendidikan,
          jenjang_akademik: data.jenjang_akademik,
          nidn: nidntemp,
          no_hp: data.no_hp,
          email: data.email,
          foto: data.foto,
          status: data.status,
          tanggal_meninggal: data.tanggal_meninggal,
          keahlian_1: keahlian_1,
          keahlian_2: keahlian_2,
          keahlian_3: keahlian_3,
        });
        setDataDetail(data);
        setImgFromApi(data.foto);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Tidak Ditemukan") {
            message.error(err.response.data.message);
            history.push(`/anggotaIKDKI`);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    console.log(values.gelar_depan);
    const formData = new FormData();
    formData.append("id", userId);
    formData.append("nama_lengkap", values.nama_lengkap);
    if (values.gelar_depan !== undefined && values.gelar_depan !== "" && values.gelar_depan !== null) {
      formData.append("gelar_depan", values.gelar_depan);
    }

    if (values.gelar_belakang !== undefined && values.gelar_belakang !== "" && values.gelar_belakang !== null)
      formData.append("gelar_belakang", values.gelar_belakang);

    if (values.jenjang !== undefined && values.jenjang !== "" && values.jenjang !== null) 
      formData.append("jenjang", values.jenjang);

    if (values.pendidikan !== undefined && values.pendidikan !== "" && values.pendidikan !== null) 
      formData.append("pendidikan", values.pendidikan);

    if (values.jenjang_akademik !== undefined && values.jenjang_akademik !== "" && values.jenjang_akademik !== null) 
      formData.append("jenjang_akademik", values.jenjang_akademik);

    if (values.nidn !== undefined && values.nidn !== "" && values.nidn !== null) 
      formData.append("nidn", values.nidn);

    if (values.keahlian_1 !== undefined && values.keahlian_1 !== "" && values.keahlian_1 !== null)
     formData.append("keahlian_1", values.keahlian_1);

    if (values.keahlian_2 !== undefined && values.keahlian_2 !== "" && values.keahlian_2 !== null) 
      formData.append("keahlian_2", values.keahlian_2);

    if (values.keahlian_3 !== undefined && values.keahlian_3 !== "" && values.keahlian_3 !== null) 
      formData.append("keahlian_3", values.keahlian_3);
    
    formData.append("prodi", values.prodi);
    formData.append("no_hp", values.no_hp);
    formData.append("email", values.email);
    formData.append("password", values.password);
    formData.append("status", values.status);
    formData.append("tanggal_meninggal", values.tanggal_meninggal);
    if (imgApi) formData.append("foto", imgApi);

    myAxios
      .post(`ubahDosenByAdmin`, formData, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Ubah data anggota berhasil!");
        setLoading(false);
        history.push(`/anggotaIKDKI`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
          setUser(null);
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImg(URL.createObjectURL(img));
      setImgApi(img);
      form.setFieldsValue({
        foto: img,
      });
    }
  };

  const onReset = () => {
    var data = dataDetail;
    form.setFieldsValue({
      nama_lengkap: data.nama_lengkap,
      gelar_depan: data.gelar_depan,
      gelar_belakang: data.gelar_belakang,
      tempat_lahir: data.tempat_lahir,
      tanggal_lahir: moment(data.tanggal_lahir),
      institusi_asal: data.institusi_asal,
      kota_institusi: data.kota_institusi,
      provinsi_institusi: data.provinsi_institusi,
      prodi: data.prodi,
      jenjang: data.jenjang,
      pendidikan: data.pendidikan,
      jenjang_akademik: data.jenjang_akademik,
      nidn: data.nidn,
      no_hp: data.no_hp,
      email: data.email,
      foto: data.foto,
      status: data.status,
      tanggal_meninggal: moment(data.tanggal_meninggal),
    });
    setImgFromApi(data.foto);
    setImg(null);
  };

  const hpValidation = async (rule, value, callback) => {
    if (value === "" || value === undefined) {
      rule.message = "Bagian ini wajib diisi";
      form.setFields({
        no_hp: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else if (value < 0) {
      rule.message = "Nomor Telepon tidak valid!";
      form.setFields({
        no_hp: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else {
      await callback();
    }
  };

  const onChangeTanggalLahir = (evt) => {};

  const nidnValidator = async (rule, value, callback) => {
    // if (value !== undefined && value !== "") {
    //   if (value.length !== 10) {
    //     rule.message = "NIDN harus 10 digit!";
    //     form.setFields({
    //       nidn: {
    //         value: value,
    //         errors: [new Error("forbid ha")],
    //       },
    //     });
    //   }
    // } else {
    //   await callback();
    // }
  };

  return (
    <div
      className="editAnggota    "
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Edit Anggota</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {!dataDetail && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {dataDetail && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            labelAlign="left"
            label="Nama Lengkap"
            name="nama_lengkap"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input />
          </Form.Item>
          <Row justify="space-between">
            <Col md={11} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Gelar Depan"
                name="gelar_depan"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={11} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Gelar Belakang"
                name="gelar_belakang"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col md={11} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Tempat Lahir"
                name="tempat_lahir"
                rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={11} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Tanggal Lahir"
                name="tanggal_lahir"
                rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
              >
                <DatePicker
                  disabled
                  style={{ width: "100%" }}
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  onChange={onChangeTanggalLahir}
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            labelAlign="left"
            label="Nama Institusi"
            name="institusi_asal"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Kota"
            name="kota_institusi"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Provinsi"
            name="provinsi_institusi"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Program Studi"
            name="prodi"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input />
          </Form.Item>
          <Row justify="space-between">
            <Col md={7} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Jenjang Mengajar"
                name="jenjang"
              >
                <Select>
                  <Select.Option value="D1">D1</Select.Option>
                  <Select.Option value="D2">D2</Select.Option>
                  <Select.Option value="D3">D3</Select.Option>
                  <Select.Option value="S1">S1</Select.Option>
                  <Select.Option value="S2">S2</Select.Option>
                  <Select.Option value="S3">S3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={7} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Pendidikan Terkahir"
                name="pendidikan"
              >
                <Select>
                  <Select.Option value="S1">S1</Select.Option>
                  <Select.Option value="S2">S2</Select.Option>
                  <Select.Option value="S3">S3</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={7} xs={24}>
              <Form.Item
                labelAlign="left"
                label="Jenjang Akademik"
                name="jenjang_akademik"
              >
                <Select>
                  <Select.Option value="Tenaga Pengajar">
                    Tenaga Pengajar
                  </Select.Option>
                  <Select.Option value="Asisten Ahli">
                    Asisten Ahli
                  </Select.Option>
                  <Select.Option value="Lektor">Lektor</Select.Option>
                  <Select.Option value="Lektor Kepala">
                    Lektor Kepala
                  </Select.Option>
                  <Select.Option value="Guru Besar">Guru Besar</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col md={7} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Bidang Keahlian 1"
                name="keahlian_1"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={7} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Bidang Keahlian 2"
                name="keahlian_2"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={7} xs={24}>
              <Form.Item
                labelAlign="left"
                label="Bidang Keahlian 3"
                name="keahlian_3"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            labelAlign="left"
            label="NIDN"
            name="nidn"
            rules={[{ validator: nidnValidator }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Nomor Handphone"
            name="no_hp"
            rules={[{ required: true, validator: hpValidation }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Masukan email yang valid",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Kata Sandi"
            name="password"
            rules={[
              {
                message: "Kata sandi minimal terdiri dari 6 karakter!",
                min: 6,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Row justify="space-between">
            <Col md={11} xs={11}>
              <Form.Item labelAlign="left" label="Status" name="status">
                <Select>
                  <Select.Option value="Aktif">Aktif</Select.Option>
                  <Select.Option value="Tidak Aktif">Tidak Aktif</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={11} xs={11}>
              <Form.Item
                labelAlign="left"
                label="Tanggal Meninggal"
                name="tanggal_meninggal"
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  onChange={onChangeTanggalLahir}
                  disabledDate={(current) => {
                    return current > moment();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="foto"
            label="Unggah Gambar"
            labelAlign="left"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <input
              style={{ marginBottom: "10px" }}
              type="file"
              name="myImage"
              onChange={onImageChange}
            />
            <Image
              name="foto"
              fallback={DefImg} // default image
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
              }}
              src={
                img ? img : `https://api-ikdki.arkin-dev.com/public/images/${imgFromApi}`
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              // type='primary'
              danger
              onClick={onReset}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditAnggota;
