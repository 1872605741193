import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../../myAxios";
import { UserContext } from "../../../../context/UserContext";
import { useHistory, useParams } from "react-router-dom";
import { Form, Input, Button, message, DatePicker, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import "./EditPenelitian.css";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const EditPenelitian = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState(null);

  const { userId } = useParams();

  let history = useHistory();
  useEffect(() => {
    if (dataDetail === null) {
      getDataPenelitian();
    }
  });

  const getDataPenelitian = () => {
    myAxios
      .get(`penelitianDosenEdit/${userId}/${user.id_dosen}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        form.setFieldsValue({
          tanggal_mulai: moment(data.tanggal_mulai),
          bidang: data.bidang,
          topik: data.topik,
        });

        setDataDetail(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Penelitian Tidak Ditemukan") {
            message.error(err.response.data.message);
            history.push(`/penelitianIndividu`);
          } else {
            message.error(err.response.data.message);
            // history.push(`/tabelPenelitian`);
          }
        }
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    let newObj = {
      id_dosen: user.id_dosen,
      id: dataDetail.id,
      tanggal_mulai: moment(values.tanggal_mulai).format(),
      bidang: values.bidang,
      topik: values.topik,
    };

    myAxios
      .put(`penelitianDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Ubah data penelitian berhasil!");
        setLoading(false);
        history.push(`/penelitianIndividu`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onReset = () => {
    var data = dataDetail;
    form.setFieldsValue({
      tanggal_mulai: moment(data.tanggal_mulai),
      bidang: data.bidang,
      topik: data.topik,
      sumber_biaya: data.sumber_biaya,
      budget: data.budget,
      actual: data.actual,
    });
  };

  const onChangeTanggalMulai = (evt) => {};

  return (
    <div
      className="editPenelitian"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Edit Penelitian</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>

      {!dataDetail && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {dataDetail && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            labelAlign="left"
            label="Tanggal Mulai"
            name="tanggal_mulai"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onChange={onChangeTanggalMulai}
              Date={(current) => {
                return current > moment();
              }}
            />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Bidang Penelitian"
            name="bidang"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Penelitian Topik"
            name="topik"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={onReset}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditPenelitian;
