import React, { Component } from "react";
import "./MenuCustom.css";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import {
  PersonFill,
  PersonPlusFill,
  PeopleFill,
  InfoLg,
  JournalPlus,
  FileBarGraphFill,
  ClipboardCheck,
  CheckSquare,
  Journals,
  ClipboardData,
  JournalRichtext,
  Book,
  Search,
  DoorClosed,
} from "react-bootstrap-icons";

const { SubMenu } = Menu;

class MenuCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    const user = this.context[0];
    this.setState({
      role: user.role,
    });
  }

  render() {
    return (
      <div>
        {this.state.role !== "Dosen" && (
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            {this.state.role === "Super Admin" && (
              <SubMenu
                key="sub1"
                icon={<PersonFill color="#123160" />}
                title="Profil Admin"
              >
                <Menu.Item key="1" style={{ margin: "0" }}>
                  <Link className="link" to="/profil">
                    Profil
                  </Link>
                </Menu.Item>
                <Menu.Item key="2" style={{ margin: "0" }}>
                  <Link className="link" to="/tambahAdmin">
                    Tambah Admin
                  </Link>
                </Menu.Item>
                <Menu.Item key="3" style={{ margin: "0" }}>
                  <Link className="link" to="/lihatAdmin">
                    Lihat Admin
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}

            {this.state.role === "Admin" && (
              <Menu.Item
                key="1"
                style={{ margin: "0" }}
                icon={<PersonFill color="#123160" />}
              >
                <Link className="link" to="/profil">
                  Profil
                </Link>
              </Menu.Item>
            )}

            <Menu.Item
              key="4"
              icon={<PersonPlusFill color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/">
                Calon Anggota
              </Link>
            </Menu.Item>

            <Menu.Item
              key="5"
              icon={<PeopleFill color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/anggotaIKDKI">
                Anggota IKDKI
              </Link>
            </Menu.Item>

            {this.state.role === "Super Admin" && (
              <Menu.Item
                key="11"
                icon={<DoorClosed color="#123160" />}
                style={{ margin: "0" }}
              >
                <Link className="link" to="/institusi">
                  Institusi
                </Link>
              </Menu.Item>
            )}

            <Menu.Item
              key="6"
              icon={<JournalPlus color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/reporting">
                Reporting
              </Link>
            </Menu.Item>

            <Menu.Item
              key="7"
              icon={<CheckSquare color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/konfirmasiEdit">
                Konfirmasi Edit User
              </Link>
            </Menu.Item>

            <Menu.Item
              key="8"
              icon={<FileBarGraphFill color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/tabelPengabdian">
                Tabel Pengabdian
              </Link>
            </Menu.Item>

            <Menu.Item
              key="9"
              icon={<Journals color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/tabelPenelitian">
                Tabel Penelitian
              </Link>
            </Menu.Item>

            <Menu.Item
              key="19"
              icon={<JournalRichtext color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/tabelPublikasi">
                Tabel Publikasi
              </Link>
            </Menu.Item>

            <Menu.Item
              key="20"
              icon={<Book color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/tabelPengajaran">
                Tabel Pengajaran
              </Link>
            </Menu.Item>

            <Menu.Item
              key="10"
              icon={<ClipboardCheck color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/logUser">
                Log User
              </Link>
            </Menu.Item>

            {/* <Menu.Item
              key="11"
              icon={<InfoLg color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/info">
                Info
              </Link>
            </Menu.Item> */}
          </Menu>
        )}
        {this.state.role === "Dosen" && (
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            <SubMenu
              key="sub2"
              icon={<PersonFill color="#123160" />}
              title="Profil Saya"
            >
              <Menu.Item key="12" style={{ margin: "0" }}>
                <Link className="link" to="/kartuID">
                  Kartu ID
                </Link>
              </Menu.Item>

              <Menu.Item key="13" style={{ margin: "0" }}>
                <Link className="link" to="/editDosen">
                  Detail Data
                </Link>
              </Menu.Item>
              <Menu.Item key="18" style={{ margin: "0" }}>
                <Link className="link" to="/ubahKataSandi">
                  Ubah Kata Sandi
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub3"
              icon={<ClipboardData color="#123160" />}
              title="Penelitian"
            >
              <Menu.Item key="14" style={{ margin: "0" }}>
                <Link className="link" to="/publicPenelitian">
                  Public
                </Link>
              </Menu.Item>

              <Menu.Item key="15" style={{ margin: "0" }}>
                <Link className="link" to="/penelitianIndividu">
                  Individu
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu
              key="sub4"
              icon={<PeopleFill color="#123160" />}
              title="Abmas"
            >
              <Menu.Item key="16" style={{ margin: "0" }}>
                <Link className="link" to="/publicPengabdian">
                  Public
                </Link>
              </Menu.Item>

              <Menu.Item key="17" style={{ margin: "0" }}>
                <Link className="link" to="/pengabdianIndividu">
                  Individu
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="sub5"
              icon={<JournalRichtext color="#123160" />}
              title="Publikasi"
            >
              <Menu.Item key="20" style={{ margin: "0" }}>
                <Link className="link" to="/publicPublikasi">
                  Public
                </Link>
              </Menu.Item>

              <Menu.Item key="21" style={{ margin: "0" }}>
                <Link className="link" to="/publikasiIndividu">
                  Individu
                </Link>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="sub6"
              icon={<Book color="#123160" />}
              title="Pengajaran"
            >
              <Menu.Item key="20" style={{ margin: "0" }}>
                <Link className="link" to="/publicPengajaran">
                  Public
                </Link>
              </Menu.Item>

              <Menu.Item key="21" style={{ margin: "0" }}>
                <Link className="link" to="/pengajaranIndividu">
                  Individu
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="6"
              icon={<JournalPlus color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/reporting" className="reportinUser">
                Reporting
              </Link>
            </Menu.Item>
            <Menu.Item
              key="7"
              icon={<Search color="#123160" />}
              style={{ margin: "0" }}
            >
              <Link className="link" to="/pencarian" className="reportinUser">
                Pencarian
              </Link>
            </Menu.Item>
          </Menu>
        )}
      </div>
    );
  }
}

export default MenuCustom;
