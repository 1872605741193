import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../../myAxios";
import { UserContext } from "../../../../context/UserContext";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message, Spin, Select } from "antd";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import "./EditPengabdian.css";
import CurrencyInput from "react-currency-input";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const TambahBiaya = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataDetail] = useState(null);
  const [topik, setTopik] = useState(null);
  const [singleTopik, setSingleTopik] = useState(null);

  const [tempCek, setTempCek] = useState({
    budget: "0",
    sumber_biaya: "",
    actual: "0",
  });

  let history = useHistory();
  useEffect(() => {
    if (topik === null) {
      getTopik();
    }
  });

  const onChange = (value) => {
    setSingleTopik(value);
  };
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const onFinish = (values) => {
    setLoading(true);
    var tempBudget = values.budget.replace("Rp. ", "");
    var tempActual = values.actual.replace("Rp. ", "");
    let newObj = {
      id_dosen: user.id_dosen,
      sumber_biaya: values.sumber_biaya,
      budget: tempBudget.replaceAll(".", ""),
      actual: tempActual.replaceAll(".", ""),
      id_abmas: singleTopik,
    };

    myAxios
      .post(`biayaAbmasDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Tambah data biaya pengabdian berhasil!");
        setLoading(false);
        history.push(`/pengabdianIndividu`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onChangeValid = (evt) => {
    setTempCek({ ...tempCek, [evt.target.name]: evt.target.value });
  };

  const getTopik = () => {
    myAxios
      .get(`topikAbmas/${user.id_dosen}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        setTopik(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };
  const onFinishFailed = (errorInfo) => {};

  const onReset = () => {
    var data = dataDetail;
    form.setFieldsValue({
      tanggal_mulai: moment(data.tanggal_mulai),
      bidang: data.bidang,
      topik: data.topik,
      sumber_biaya: data.sumber_biaya,
      budget: data.budget,
      actual: data.actual,
    });
    setTempCek({
      budget: "0",
      sumber_biaya: "",
      actual: "0",
    });
  };

  return (
    <div
      className="TambahBiaya"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Tambah Biaya</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {!topik && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {topik && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {topik && (
            <Form.Item
              labelAlign="left"
              label="Topik"
              name="topik"
              rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
            >
              <Select
                style={{ width: "100%" }}
                autoComplete="off"
                showSearch
                placeholder="Cari Topik"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                defaultValue={singleTopik}
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
              >
                {topik.map((val, item) => (
                  <Option
                    key={val.id}
                    value={val.id}
                    style={{ fontWeight: "bold" }}
                  >
                    {val.topik}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            labelAlign="left"
            label="Sumber Biaya"
            name="sumber_biaya"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input name="sumber_biaya" onChange={onChangeValid} />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Budget"
            name="budget"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <CurrencyInput
              className="ant-input"
              name="budget"
              onChangeEvent={onChangeValid}
              thousandSeparator="."
              precision="0"
              prefix="Rp. "
            />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Actual"
            name="actual"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <CurrencyInput
              className="ant-input"
              name="actual"
              onChangeEvent={onChangeValid}
              thousandSeparator="."
              precision="0"
              prefix="Rp. "
            />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={onReset}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default TambahBiaya;
