import React from 'react';
import myAxios from '../../../../myAxios';
import moment from 'moment';
import './TabelPengabdianIndividu.css';
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
  Space,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { UserContext } from '../../../../context/UserContext.jsx';
import TabelBiayaPengabdian from './TabelBiayaPengabdian';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class TabelPengabdian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: '',
      searchedColumn: '',
      loading: false,
      daftarPengabdian: null,
      tempdaftarPengabdian: null,
      visible: false,
      dataDetail: null,
      counter: 0,
      loadingAct: false,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarPengabdian === null) {
      this.getPengabdian();
    }
  }

  getPengabdian = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`pengabdianDosen/${user.id_dosen}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data !== null) {
          data.map((el) => {
            if (el.budget !== '-') el.budgetNumber = Number(el.budget);
            else el.budgetNumber = '-';
            if (el.actual !== '-') el.actualNumber = Number(el.actual);
            else el.actualNumber = '-';
            el.tanggal_mulai = moment(el.tanggal_mulai).format('LL');
          });

          this.setState({
            daftarPengabdian: data,
            tempdaftarPengabdian: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarPengabdian: null,
            tempdaftarPengabdian: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearch = (evt) => {
    if (this.state.tempdaftarPengabdian) {
      if (evt.target.value === '') {
        this.setState({ daftarPengabdian: this.state.tempdaftarPengabdian });
      } else {
        this.setState({
          daftarPengabdian: this.state.tempdaftarPengabdian.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.tempat_pengabdian
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.tanggal_mulai
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.topik.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  onEditPengabdian = (index) => {
    window.location.pathname = `/editPengabdianIndividu/${index}`;
  };
  onTambahPengabdian = () => {
    window.location.pathname = `/tambahPengabdian`;
  };

  onDelete = (index) => {
    const user = this.context[0];
    let newObj = {
      id_dosen: user.id_dosen,
      id: index,
    };
    this.setState({ loadingAct: true });
    myAxios
      .put(`deletePengabdianDosen`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        this.getPengabdian();
        this.setState({
          loadingAct: false,
        });
        window.location.reload();
        message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loadingAct: false,
        });
      });
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: '7%',
      },
      {
        title: 'Nama Anggota',
        dataIndex: 'nama_gelar',
        key: 'nama_gelar',
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: 'Tempat Pengabdian',
        dataIndex: 'tempat_pengabdian',
        key: 'tempat_pengabdian',
        filteredValue: filteredInfo.tempat_pengabdian || null,
        sorter: (a, b) =>
          a.tempat_pengabdian.length - b.tempat_pengabdian.length,
        ellipsis: true,
      },
      {
        title: 'Pengabdian Mulai',
        dataIndex: 'tanggal_mulai',
        key: 'tanggal_mulai',
        filteredValue: filteredInfo.tanggal_mulai || null,
        sorter: (a, b) => a.tanggal_mulai.length - b.tanggal_mulai.length,
        ellipsis: true,
      },

      {
        title: 'Topik',
        dataIndex: 'topik',
        key: 'topik',
        filteredValue: filteredInfo.topik || null,
        sorter: (a, b) => a.topik.length - b.topik.length,
        ellipsis: true,
      },
      {
        align: 'center',
        title: 'Aksi',
        dataIndex: 'id',
        key: 'id',

        render: (dataIndex) => (
          <div className='aksiPengabdian'>
            {!this.state.loadingAct && (
              <Row justify='space-around'>
                <Col style={{ marginTop: '2px' }}>
                  <Tooltip
                    placement='bottom'
                    title='Edit Pengabdian'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      className='btn-edit'
                      onClick={() => this.onEditPengabdian(dataIndex)}>
                      <PencilFill />
                    </Button>
                  </Tooltip>
                </Col>
                <Col style={{ marginTop: '2px' }}>
                  <Tooltip
                    placement='bottom'
                    title='Hapus Pengabdian'
                    color='#1f1f1f'
                    key='white'>
                    <Popconfirm
                      placement='left'
                      title={'Hapus Pengabdian ?'}
                      onConfirm={() => this.onDelete(dataIndex)}
                      okText='Hapus'
                      cancelText='Batal'>
                      <Button className='btn-tolak'>
                        <TrashFill />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Col>
              </Row>
            )}
            {this.state.loadingAct && <Spin indicator={antIcon} />}
          </div>
        ),
      },
    ];
    return (
      <div>
        <div
          className='pengabdian'
          style={{
            padding: '25px 30px',
            margin: '25px 30px',
            backgroundColor: 'white',
          }}>
          <h1
            style={{
              fontSize: 'x-large',
              color: '#001529',
              textTransform: 'uppercase',
            }}>
            <strong>Tabel Pengabdian Individu</strong>
          </h1>
          <div
            style={{
              border: '1px solid #8C98AD',
              marginTop: '-10px',
              marginBottom: '15px',
            }}></div>
          <Row style={{ marginBottom: 16 }} justify='space-between'>
            <Col md={4}>
              <Space>
                <Button
                  className='btn-hapus-filter'
                  onClick={this.clearFilters}>
                  Hapus Filter
                </Button>
                <Button
                  className='btn-hapus-filter'
                  onClick={this.onTambahPengabdian}>
                  Tambah Pengabdian
                </Button>
              </Space>
            </Col>

            <Col md={12} xs={24}>
              <Input
                style={{ width: '100%' }}
                placeholder='Cari data pengabdian disini'
                onChange={this.onChangeSearch}
              />
            </Col>
          </Row>
          <Table
            loading={this.state.loading}
            loadingIndicator={antIcon}
            scroll={{ x: 900, y: 1500 }}
            columns={columns}
            dataSource={this.state.daftarPengabdian}
            onChange={this.handleChange}
            size='middle'
          />
        </div>
        <TabelBiayaPengabdian />
      </div>
    );
  }
}

export default TabelPengabdian;
