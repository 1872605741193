import React from "react";
import myAxios from "../../../../myAxios";
import moment from "moment";
import "./TabelPenelitianIndividu.css";
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
  Space,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { PencilFill, TrashFill } from "react-bootstrap-icons";
import { UserContext } from "../../../../context/UserContext.jsx";
import TabelBiayaPenelitian from "./TabelBiayaPenelitian";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class TabelPenelitian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchedColumn: "",
      loading: false,
      loadingAct: false,
      daftarPenelitian: null,
      tempdaftarPenelitian: null,
      visible: false,
      dataDetail: null,
      counter: 0,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarPenelitian === null) {
      this.getPenelitian();
    }
  }

  componentDidUpdate() {}

  getPenelitian = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`penelitianDosen/${user.id_dosen}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data !== null) {
          data.map((el) => {
            if (el.budget !== "-") el.budgetNumber = Number(el.budget);
            else el.budgetNumber = "-";
            if (el.actual !== "-") el.actualNumber = Number(el.actual);
            else el.actualNumber = "-";
            el.tanggal_mulai = moment(el.tanggal_mulai).format("LL");
          });

          this.setState({
            daftarPenelitian: data,
            tempdaftarPenelitian: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarPenelitian: null,
            tempdaftarPenelitian: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearch = (evt) => {
    if (this.state.tempdaftarPenelitian) {
      if (evt.target.value === "") {
        this.setState({ daftarPenelitian: this.state.tempdaftarPenelitian });
      } else {
        this.setState({
          daftarPenelitian: this.state.tempdaftarPenelitian.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.bidang.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.tanggal_mulai
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.topik.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  onEditPenelitian = (index) => {
    this.setState({
      loadingAct: true,
    });
    window.location.pathname = `/editPenelitianIndividu/${index}`;
  };
  onTambahPenelitian = () => {
    this.setState({
      loading: tableLoading,
    });
    window.location.pathname = `/tambahPenelitian`;
  };

  onDelete = (index) => {
    const user = this.context[0];
    let newObj = {
      id_dosen: user.id_dosen,
      id: index,
    };
    this.setState({
      loadingAct: true,
    });
    myAxios
      .put(`deletePenelitianDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        this.getPenelitian();
        this.setState({
          loadingAct: false,
        });
        window.location.reload();
        message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loadingAct: false,
        });
      });
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: "7%",
      },
      {
        title: "Nama Anggota",
        dataIndex: "nama_gelar",
        key: "nama_gelar",
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: "Bidang Penelitian",
        dataIndex: "bidang",
        key: "bidang",
        filteredValue: filteredInfo.bidang || null,
        sorter: (a, b) => a.bidang.length - b.bidang.length,
        ellipsis: true,
      },
      {
        title: "Penelitian Mulai",
        dataIndex: "tanggal_mulai",
        key: "tanggal_mulai",
        filteredValue: filteredInfo.tanggal_mulai || null,
        sorter: (a, b) => a.tanggal_mulai.length - b.tanggal_mulai.length,
        ellipsis: true,
      },

      {
        title: "Topik",
        dataIndex: "topik",
        key: "topik",
        filteredValue: filteredInfo.topik || null,
        sorter: (a, b) => a.topik.length - b.topik.length,
        ellipsis: true,
      },
      {
        align: "center",
        title: "Aksi",
        dataIndex: "id",
        key: "id",

        render: (dataIndex) => (
          <div className="aksiPenelitian">
            {!this.state.loadingAct && (
              <Row justify="space-around">
                <Col style={{ marginTop: "2px" }}>
                  <Tooltip
                    placement="bottom"
                    title="Edit penelitian"
                    color="#1f1f1f"
                    key="white"
                  >
                    <Button
                      className="btn-edit"
                      onClick={() => this.onEditPenelitian(dataIndex)}
                    >
                      <PencilFill />
                    </Button>
                  </Tooltip>
                </Col>
                <Col style={{ marginTop: "2px" }}>
                  <Tooltip
                    placement="bottom"
                    title="Hapus penelitian"
                    color="#1f1f1f"
                    key="white"
                  >
                    <Popconfirm
                      placement="left"
                      title={"Hapus penelitian ?"}
                      onConfirm={() => this.onDelete(dataIndex)}
                      okText="Hapus"
                      cancelText="Batal"
                    >
                      <Button className="btn-tolak">
                        <TrashFill />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Col>
              </Row>
            )}
            {this.state.loadingAct && <Spin indicator={antIcon} />}
          </div>
        ),
      },
    ];
    return (
      <div>
        <div
          className="penelitian"
          style={{
            padding: "25px 30px",
            margin: "25px 30px",
            backgroundColor: "white",
          }}
        >
          <h1
            style={{
              fontSize: "x-large",
              color: "#001529",
              textTransform: "uppercase",
            }}
          >
            <strong>Tabel Penelitian Individu</strong>
          </h1>
          <div
            style={{
              border: "1px solid #8C98AD",
              marginTop: "-10px",
              marginBottom: "15px",
            }}
          ></div>
          <Row style={{ marginBottom: 16 }} justify="space-between">
            <Col md={4}>
              <Space>
                <Button
                  className="btn-hapus-filter"
                  onClick={this.clearFilters}
                >
                  Hapus Filter
                </Button>
                <Button
                  className="btn-hapus-filter"
                  onClick={this.onTambahPenelitian}
                >
                  Tambah Penelitian
                </Button>
              </Space>
            </Col>

            <Col md={12} xs={24}>
              <Input
                style={{ width: "100%" }}
                placeholder="Cari data penelitian disini"
                onChange={this.onChangeSearch}
              />
            </Col>
          </Row>
          <Table
            loading={this.state.loading}
            loadingIndicator={antIcon}
            scroll={{ x: 900, y: 1500 }}
            columns={columns}
            dataSource={this.state.daftarPenelitian}
            onChange={this.handleChange}
            size="middle"
          />
        </div>
        <TabelBiayaPenelitian />
      </div>
    );
  }
}

export default TabelPenelitian;
