import React from 'react';
import myAxios from '../../../../myAxios';
import moment from 'moment';
import './TabelPenelitian.css';
import { Table, Button, Input, Row, Col, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../../../context/UserContext.jsx';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class TabelPenelitian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: '',
      searchedColumn: '',
      loading: false,
      daftarPenelitian: null,
      tempdaftarPenelitian: null,
      visible: false,
      dataDetail: null,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarPenelitian === null) {
      this.getPenelitian();
    }
  }

  getPenelitian = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`penelitianPublic`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          data.map((el) => {
            if (el.budget !== '-') el.budgetNumber = Number(el.budget);
            else el.budgetNumber = '-';
            if (el.actual !== '-') el.actualNumber = Number(el.actual);
            else el.actualNumber = '-';
            el.tanggal_mulai = moment(el.tanggal_mulai).format('LL');
          });

          this.setState({
            daftarPenelitian: data,
            tempdaftarPenelitian: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarPenelitian: null,
            tempdaftarPenelitian: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearch = (evt) => {
    if (this.state.tempdaftarPenelitian) {
      if (evt.target.value === '') {
        this.setState({ daftarPenelitian: this.state.tempdaftarPenelitian });
      } else {
        this.setState({
          daftarPenelitian: this.state.tempdaftarPenelitian.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.bidang.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.tanggal_mulai
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.topik.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: '7%',
      },
      {
        title: 'Nama Anggota',
        dataIndex: 'nama_gelar',
        key: 'nama_gelar',
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: 'Bidang Penelitian',
        dataIndex: 'bidang',
        key: 'bidang',
        filteredValue: filteredInfo.bidang || null,
        sorter: (a, b) => a.bidang.length - b.bidang.length,
        ellipsis: true,
      },
      {
        title: 'Penelitian Mulai',
        dataIndex: 'tanggal_mulai',
        key: 'tanggal_mulai',
        filteredValue: filteredInfo.tanggal_mulai || null,
        sorter: (a, b) => a.tanggal_mulai.length - b.tanggal_mulai.length,
        ellipsis: true,
      },

      {
        title: 'Topik',
        dataIndex: 'topik',
        key: 'topik',
        filteredValue: filteredInfo.topik || null,
        sorter: (a, b) => a.topik.length - b.topik.length,
        ellipsis: true,
      },
    ];
    return (
      <div
        className='penelitian'
        style={{
          padding: '25px 30px',
          margin: '25px 30px',
          backgroundColor: 'white',
        }}>
        <h1
          style={{
            fontSize: 'x-large',
            color: '#001529',
            textTransform: 'uppercase',
          }}>
          <strong>Tabel Penelitian</strong>
        </h1>
        <div
          style={{
            border: '1px solid #8C98AD',
            marginTop: '-10px',
            marginBottom: '15px',
          }}></div>
        <Row style={{ marginBottom: 16 }} justify='space-between'>
          <Col md={12}>
            <Button className='btn-hapus-filter' onClick={this.clearFilters}>
              Hapus Filter
            </Button>
          </Col>
          <Col md={12}>
            <Input
              style={{ width: '100%' }}
              placeholder='Cari data penelitian disini'
              onChange={this.onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          scroll={{ x: 900, y: 1500 }}
          columns={columns}
          dataSource={this.state.daftarPenelitian}
          onChange={this.handleChange}
          size='middle'
        />
      </div>
    );
  }
}

export default TabelPenelitian;
