import React, { useState, useContext, useEffect } from 'react';
import myAxios from '../../../myAxios';
import { UserContext } from '../../../context/UserContext';
import { Form, Input, Button, message } from 'antd';
import './UbahKataSandi.css';
import { useHistory } from 'react-router-dom';

const UbahKataSandi = () => {
  const [user, setUser] = useContext(UserContext);
  const [condition, setCon] = useState(false);
  const [passwordTemp, setPasswordTemp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  let history = useHistory();

  useEffect(() => {
    if (!condition) {
      form.setFieldsValue({
        nama: user.nama,
        email: user.email,
      });
      setCon(true);
    }
  });

  const onFinish = (values) => {
    setLoading(true);
    let newObj = {
      id_user: user.id_user,
      id_dosen: user.id_dosen,
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
    };

    myAxios
      .put(`gantiPassDosen`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success('Kata sandi berhasil diubah!');
        setLoading(false);
        history.push('/home');
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          setUser(null);
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onChangePassword = (evt) => {
    setPasswordTemp(evt.target.value);
  };

  const confirmPassword = async (rule, value, callback) => {
    if (value === '' || value === undefined) {
      rule.message = 'Bagian ini wajib diisi';
      form.setFields({
        reNewPass: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else if (value !== passwordTemp) {
      rule.message = 'Kata sandi tidak cocok!';
      form.setFields({
        reNewPass: {
          value: value,
          errors: [new Error('forbid ha')],
        },
      });
    } else {
      await callback();
    }
  };

  return (
    <div
      className='ubahKataSandi'
      style={{
        padding: '25px 30px',
        margin: '25px 30px',
        backgroundColor: 'white',
      }}>
      <h1
        style={{
          fontSize: 'x-large',
          color: '#001529',
          textTransform: 'uppercase',
        }}>
        <strong>Ubah Kata Sandi</strong>
      </h1>
      <div
        style={{
          border: '1px solid #8C98AD',
          marginTop: '-10px',
          marginBottom: '15px',
        }}></div>
      <Form
        layout='vertical'
        form={form}
        name='basic'
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item
          labelAlign='left'
          label='Nama'
          name='nama'
          rules={[
            {
              required: true,
              message: 'Bagian ini wajib diisi',
            },
          ]}>
          <Input disabled />
        </Form.Item>
        <Form.Item labelAlign='left' label='Email' name='email'>
          <Input disabled />
        </Form.Item>

        <Form.Item
          labelAlign='left'
          label='Kata Sandi Lama'
          name='oldPassword'
          rules={[
            {
              required: true,
              message: 'Masukan kata sandi lama anda!',
            },
          ]}>
          <Input.Password onChange={onChangePassword} />
        </Form.Item>

        <Form.Item
          labelAlign='left'
          label='Kata Sandi Baru'
          name='newPassword'
          rules={[
            {
              required: true,
              message: 'Kata sandi minimal terdiri dari 6 karakter!',
              min: 6,
            },
          ]}>
          <Input.Password onChange={onChangePassword} />
        </Form.Item>

        <Form.Item
          labelAlign='left'
          label='Konfirmasi Kata Sandi'
          name='reNewPass'
          rules={[
            {
              validator: confirmPassword,
              required: true,
            },
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ width: 'fit-content', marginRight: '5px' }}
            danger
            onClick={() =>
              form.setFieldsValue({
                newPassword: null,
                oldPassword: null,
                reNewPass: null,
              })
            }
            loading={loading}>
            Reset
          </Button>
          <Button
            style={{ width: 'fit-content' }}
            type='primary'
            htmlType='submit'
            loading={loading}>
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UbahKataSandi;
