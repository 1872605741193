import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../myAxios";
import { useHistory, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { Form, Input, Button, message, Select, Spin, Modal } from "antd";
import "./TambahInstitusi.css";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const { Option } = Select;
const EditInstitusi = () => {
  const [user, setUser] = useContext(UserContext);
  const [institusi, setInstitusi] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  let history = useHistory();
  let { idInstitusi } = useParams();

  useEffect(() => {
    if (!institusi) {
      getInstitusi();
    }
  });

  const getInstitusi = () => {
    myAxios
      .get(`institusi/${idInstitusi}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        form.setFieldsValue({
          institusi_code: data.institusi_code,
          institusi_name: data.institusi_name,
          institusi_city: data.institusi_city,
        });

        setInstitusi(data);
        message.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  function handleChange(value) {
    console.log(`Selected: ${value}`);
  }

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      id_institusi: idInstitusi,
      institusi_code: values.institusi_code,
      institusi_name: values.institusi_name,
      institusi_city: values.institusi_city,
    };

    myAxios
      .put(`editInstitusi`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        history.push("/institusi");
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};
  const onReset = () => {
    form.setFieldsValue({
      institusi_code: institusi.institusi_code,
      institusi_name: institusi.institusi_name,
      institusi_city: institusi.institusi_city,
    });
  };

  return (
    <div
      className="profil"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Ubah Institusi</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>

      {!institusi && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {institusi && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            labelAlign="left"
            label="Kode Institusi"
            name="institusi_code"
            rules={[
              {
                required: true,
                message: "Bagian ini wajib diisi",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Nama Institusi"
            name="institusi_name"
            rules={[
              {
                required: true,
                message: "Bagian ini wajib diisi",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Kota Institusi"
            name="institusi_city"
            rules={[
              {
                message: "Masukan email yang valid",
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={onReset}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditInstitusi;
