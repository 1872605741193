import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import myAxios from "../../../myAxios";
import "./KartuID.css";
import { Row, Col, Button, message, Spin, Modal, Image } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { UserContext } from "../../../context/UserContext";
import DefImg from "../../../assets/img/defImg.png";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const KartuID = () => {
  const [user, setUser] = useContext(UserContext);
  const [dataKartu, setDataKartu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [img, setImg] = useState(null);
  const [imgApi, setImgApi] = useState(null);
  const componentRef = useRef();

  useEffect(() => {
    if (dataKartu === null) {
      getDataKartu();
    }
  });

  const getDataKartu = () => {
    myAxios
      .get(`dosen/${user.id_dosen}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setDataKartu(data);
      })
      .catch((err) => {
        // if (err.response.data.message === "Unauthenticated.") {
        //   message.error("Sesi anda telah habis!");
        //   localStorage.removeItem("user");
        //   setUser(null);
        //   window.location.pathname = "/login";
        // } else {
        // }
        setLoading(false);
        message.error(err.response.data.message);
      });
  };

  const onFinishUpdate = () => {
    if (!img) {
      message.error("Silahkan unggah foto!");
    } else {
      let formData = new FormData();
      formData.append("id", user.id_dosen);
      formData.append("foto", imgApi);
      setLoading(true);
      myAxios
        .post(`updatePhoto`, formData, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((res) => {
          message.success("Ubah Foto Berhasil");
          getDataKartu();
          setLoading(false);
          setVisible(false);
          setImg(null);
        })
        .catch((err) => {
          if (err.response.data.message === "Unauthenticated.") {
            message.error("Sesi anda telah habis!");
            localStorage.removeItem("user");
            setUser(null);
            window.location.pathname = "/login";
          } else {
            if (err.response.data.message === "Data Tidak Ditemukan") {
              message.error(err.response.data.message);
              // history.push(`/tabelPenelitian`);
            } else {
              message.error(err.response.data.message);
            }
          }
          setLoading(false);
        });
    }
  };

  const onUbahFoto = () => {
    setVisible(true);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImg(URL.createObjectURL(img));
      setImgApi(img);
    }
  };

  const onCetakCard = () => {};
  return (
    <div
      className="calonAnggota"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Kartu ID </strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {!dataKartu && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {dataKartu && (
        <div className="showCard">
          <div className="container-kartu" ref={componentRef}>
            <Row justify="center" align="middle" style={{ height: "100%" }}>
              <Col style={{ marginRight: "10px" }}>
                <div className="kartuAnggota">
                  <div className="kerangka-foto">
                    <img
                      alt="foto"
                      src={`https://api-ikdki.arkin-dev.com/public/images/${dataKartu.foto}`}
                    ></img>
                    <div className="wrapper-text">
                      <p className="namaLengkap">{dataKartu.nama_gelar}</p>
                      <p className="noAnggota">{dataKartu.no_anggota}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="kartuAnggotaBack">
                  <p className="tanggal-berlaku">{dataKartu.tahun_kartu}</p>
                </div>
              </Col>
            </Row>
          </div>
          <Row justify="center" className="btn-cetak-card">
            <Col>
              <Button type="dashed" onClick={onUbahFoto}>
                Ubah Foto
              </Button>
            </Col>
            <Col>
              <ReactToPrint
                documentTitle={`KartuAnggota_${dataKartu.no_anggota}`}
                trigger={() => {
                  return (
                    <Button type="primary" onClick={onCetakCard}>
                      Unduh Kartu
                    </Button>
                  );
                }}
                content={() => componentRef.current}
              />
            </Col>
          </Row>
          <Modal
            width={500}
            title="Ubah Foto"
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={[]}
          >
            <Row justify="center" align="middle">
              <Col md={12}>
                <Image
                  name="foto"
                  fallback={DefImg} // default image
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                  }}
                  src={
                    img
                      ? img
                      : `https://api-ikdki.arkin-dev.com/public/images/${dataKartu.foto}`
                  }
                />
              </Col>
              <Col md={12}>
                <input
                  style={{
                    marginBottom: "10px",
                    textOverflow: "ellipsis",
                    width: "95%",
                  }}
                  type="file"
                  name="myImage"
                  onChange={onImageChange}
                />
              </Col>
            </Row>
            <Button
              type="primary"
              onClick={onFinishUpdate}
              loading={loading}
              style={{ width: "100%", marginTop: "30px" }}
            >
              Simpan
            </Button>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default KartuID;
