import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../myAxios";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { Form, Input, Button, message, Select, Spin } from "antd";
import "./TambahAdmin.css";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const { Option } = Select;
const Profil = () => {
  const [user, setUser] = useContext(UserContext);
  const [province, setProvince] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  let history = useHistory();

  useEffect(() => {
    if (province === null) {
      getDataProvinsi();
    }
  });

  function handleChange(value) {
    console.log(`Selected: ${value}`);
  }

  const getDataProvinsi = () => {
    myAxios
      .get(`getProvinsiAdmin`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setProvince(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Tidak Ditemukan") {
            message.error(err.response.data.message);
            history.push(`/tabelPenelitian`);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      name: values.nama,
      email: values.email,
      password: values.password,
      provinsi: values.provinsi,
    };

    myAxios
      .post(`admin`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Admin berhasil di tambah!");
        history.push("/lihatAdmin");
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div
      className="profil"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Tambah Admin</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>

      {!province && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}

      {province && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            labelAlign="left"
            label="Nama"
            name="nama"
            rules={[
              {
                required: true,
                message: "Bagian ini wajib diisi",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelAlign="left"
            label="Wilayah"
            name="provinsi"
            rules={[
              {
                required: true,
                message: "Bagian ini wajib diisi",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder="Pilih wilayah"
              onChange={handleChange}
              style={{ width: "100%" }}
            >
              {province.map((val, item) => (
                <Option key={val.name}>{val.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Masukan email yang valid",
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Kata Sandi"
            name="password"
            rules={[
              {
                required: true,
                message: "Kata sandi minimal terdiri dari 6 karakter!",
                min: 6,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={() => form.resetFields()}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default Profil;
