import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../myAxios";
import { UserContext } from "../../../context/UserContext";
import { Form, Input, Button, message, Switch } from "antd";
import "./Profil.css";

const Profil = () => {
  const [user, setUser] = useContext(UserContext);
  const [condition, setCon] = useState(false);
  const [passwordTemp, setPasswordTemp] = useState(null);
  const [loading, setLoading] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!condition) {
      form.setFieldsValue({
        nama: user.nama,
        email: user.email,
      });
      setCon(true);
    }
  });

  const onFinish = (values) => {
    setLoading(true);
    let newObj = {
      id: user.id_user,
      name: values.nama,
      password: values.newPass,
      changePass: changePass.toString(),
    };

    myAxios
      .put(`gantiPassAdmin`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        var nama = res.data.data.name;

        var email = user.email;
        var token = user.token;
        var role = user.role;
        var id_user = user.id_user;
        var id_dosen = null;

        var currentUser = {
          nama,
          email,
          token,
          role,
          id_user,
          id_dosen,
        };
        setUser(currentUser);
        localStorage.setItem("user", JSON.stringify(currentUser));
        message.success("Profil berhasil diubah!");
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onChangePassword = (evt) => {
    setPasswordTemp(evt.target.value);
  };

  const confirmPassword = async (rule, value, callback) => {
    if (value === "" || value === undefined) {
      rule.message = "Bagian ini wajib diisi";
      form.setFields({
        reNewPass: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else if (value !== passwordTemp) {
      rule.message = "Kata sandi tidak cocok!";
      form.setFields({
        reNewPass: {
          value: value,
          errors: [new Error("forbid ha")],
        },
      });
    } else {
      await callback();
    }
  };

  const onChangeSwitch = () => {
    setChangePass(!changePass);
  };

  return (
    <div
      className="profil"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Profil Admin</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      <Form
        layout="vertical"
        form={form}
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          labelAlign="left"
          label="Nama"
          name="nama"
          rules={[
            {
              required: true,
              message: "Bagian ini wajib diisi",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item labelAlign="left" label="Email" name="email">
          <Input disabled />
        </Form.Item>
        <div style={{ marginBottom: "20px" }}>
          <Switch style={{ marginRight: "10px" }} onChange={onChangeSwitch} />
          <label>Ubah kata sandi</label>
        </div>
        {changePass && (
          <div>
            <Form.Item
              labelAlign="left"
              label="Kata Sandi Baru"
              name="newPass"
              rules={[
                {
                  required: true,
                  message: "Kata sandi minimal terdiri dari 6 karakter!",
                  min: 6,
                },
              ]}
            >
              <Input.Password onChange={onChangePassword} />
            </Form.Item>

            <Form.Item
              labelAlign="left"
              label="Konfirmasi Kata Sandi"
              name="reNewPass"
              rules={[
                {
                  validator: confirmPassword,
                  required: true,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </div>
        )}
        <Form.Item>
          <Button
            style={{ width: "fit-content", marginRight: "5px" }}
            danger
            onClick={() => form.resetFields()}
            loading={loading}
          >
            Reset
          </Button>
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Profil;
