import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../../myAxios";
import { UserContext } from "../../../../context/UserContext";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import "./EditJurnal.css";

const TambahJurnal = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  let history = useHistory();
  useEffect(() => {});

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      id_dosen: user.id_dosen,
      nama_jurnal: values.nama,
      judul: values.judul,
    };

    myAxios
      .post(`jurnalDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Tambah data publikasi berhasil!");
        setLoading(false);
        history.push(`/publikasiIndividu`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div
      className="tambahJurnal"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Tambah Publikasi</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>

      <Form
        layout="vertical"
        form={form}
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          labelAlign="left"
          label="Judul Publikasi"
          name="judul"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Nama Publikasi"
          name="nama"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ width: "fit-content", marginRight: "5px" }}
            danger
            onClick={onReset}
            loading={loading}
          >
            Reset
          </Button>
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TambahJurnal;
