import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../myAxios";
import { UserContext } from "../../context/UserContext";
import { message, Card, Row, Col } from "antd";
import Chart from "react-apexcharts";
import "./Reporting.css";
import Choropleth from "./Choropleth";
import ReactWordcloud from "react-wordcloud";

const Reporting = () => {
  const [user, setUser] = useContext(UserContext);
  const [data, setData] = useState(null);
  const [word, setWord] = useState(null);

  const [bar, setBar] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["D1", "D2", "D3", "S1", "S2", "S3"],
      },
      noData: {
        text: "Loading...",
      },
      title: {
        text: "Grafik Jenjang Mengajar Dosen",
      },
    },
    series: [],
  });

  const [jenjangAk, setJenjangAk] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [
          "Tenaga Pengajar",
          "Asisten Ahli",
          "Lektor",
          "Lektor Kepala",
          "Guru Besar",
        ],
      },
      noData: {
        text: "Loading...",
      },
      title: {
        text: "Grafik Jenjang Akademik Dosen",
      },
    },

    series: [],
  });

  const [univ, setUniv] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
      noData: {
        text: "Loading...",
      },
      title: {
        text: "Grafik Tempat Mengajar Dosen",
      },
    },
    series: [],
  });

  const [pendidikan, setPendidikan] = useState({
    chartOptions: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["S2", "S3"],
      },
      noData: {
        text: "Loading...",
      },
      title: {
        text: "Grafik Pendidikan Terakhir Dosen",
      },
      labels: ["S1", "S2", "S3"],
    },
    series: [],
  });
  useEffect(() => {
    if (data === null) getJaring();
  });

  const getJaring = () => {
    myAxios
      .get(`jaring`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const jenjang = res.data.dataJenjang;
        const jenjangAkademik = res.data.dataJenjangAkademik;
        const pendidikann = res.data.dataPendidikan;

        setWord(res.data.dataKeahlian);
        setData(res.data);
        setBar((bar.series[0] = { name: "Jumlah", data: jenjang }));
        setPendidikan({
          ...pendidikan,
          series: pendidikann,
          labels: ["S1", "S2"],
        });
        setJenjangAk(
          (jenjangAk.series[0] = {
            name: "Jumlah",
            data: jenjangAkademik,
          })
        );
        setUniv({
          options: {
            ...univ.options,
            xaxis: { ...univ.options.xaxis, categories: res.data.namaUniv },
          },
          series: [{ name: "Jumlah", data: res.data.valueUniv }],
        });
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
      });
  };

  return (
    <div
      className="reporting"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Reporting</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {bar && (
        <div>
          <Row justify="space-between">
            <Col md={12}>
              <Card>
                <Chart options={bar.options} series={bar.series} type="radar" />
              </Card>
            </Col>
            <Col md={12}>
              <Card>
                <Chart
                  options={jenjangAk.options}
                  series={jenjangAk.series}
                  type="radar"
                  width="100%"
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <Chart
                  options={pendidikan.chartOptions}
                  series={pendidikan.series}
                  type="donut"
                  // height="900"
                />
              </Card>
            </Col>
            <Col md={12}>
              <Card>
                {univ && (
                  <Chart
                    options={univ.options}
                    series={univ.series}
                    type="bar"
                    height="600"
                  />
                )}
              </Card>
            </Col>
          </Row>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "40px",
              width: "100%",
            }}
          >
            <Choropleth />
          </div>
        </div>
      )}
      <h2 style={{ textAlign: "center" }}>Bidang Keahlian Dosen</h2>
      {word && <ReactWordcloud options={{rotations: 2, rotationAngles: [0, 0]}} size={[600,400]} words={word} style={{height: "800px"}}/>}
      {!word && <p style={{ textAlign: "center" }}>Tidak ada data...</p>}
    </div>
  );
};

export default Reporting;
