import React from "react";
import myAxios from "../../myAxios";
import moment from "moment";
import "./LogUser.css";
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
  Space,
  Menu,
  Dropdown,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TrashFill } from "react-bootstrap-icons";
import { UserContext } from "../../context/UserContext.jsx";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
const menu = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        Tampil user online
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        Tampil log user
      </a>
    </Menu.Item>
  </Menu>
);
class LogUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchedColumn: "",
      loading: false,
      daftarLogUser: null,
      tempdaftarLogUser: null,
      visible: false,
      dataDetail: null,
      daftarUserOnline: null,
      tempUserOnline: null,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarLogUser === null) {
      this.getLogUser();
    }
  }

  getLogUser = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`log`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data !== null) {
          data.map((el) => {
            el.created_at = moment(el.created_at).format(
              "MMMM Do YYYY, h:mm:ss a"
            );
          });
          this.setState({
            daftarLogUser: data,
            tempdaftarLogUser: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarLogUser: null,
            tempdaftarLogUser: null,
            loading: false,
          });
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({
      sortedInfo: null,
      filteredInfo: null,
      searchText: "",
      daftarLogUser: this.state.tempdaftarLogUser,
    });
  };

  onChangeSearch = (evt) => {
    this.setState({ searchText: evt.target.value });
    if (this.state.tempdaftarLogUser) {
      if (evt.target.value === "") {
        this.setState({
          daftarLogUser: this.state.tempdaftarLogUser,
        });
      } else {
        this.setState({
          daftarLogUser: this.state.tempdaftarLogUser.filter((i) => {
            return (
              i.nama_lengkap
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.keterangan
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.created_at
                .toLowerCase()
                .includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  onTerima = (param) => {
    const user = this.context[0];
    myAxios
      .put(`confirmDosen/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        let filter = this.state.daftarLogUser.filter((el) => {
          return el.id !== param;
        });
        this.setState({
          daftarLogUser: filter,
          tempdaftarLogUser: filter,
        });

        message.success("Dosen berhasil diterima!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onDelete = (param) => {
    const user = this.context[0];
    myAxios
      .put(`deleteLog/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        this.getLogUser();
        message.success("Data log berhasil di hapus!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onDetail = (index) => {
    let filter = this.state.tempdaftarLogUser.filter((el) => {
      return el.id === index;
    });
    this.setState({ dataDetail: filter[0], visible: true });
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: "7%",
      },
      {
        title: "Nama Anggota",
        dataIndex: "nama_lengkap",
        key: "nama_lengkap",
        filteredValue: filteredInfo.nama_lengkap || null,
        sorter: (a, b) => a.nama_lengkap.length - b.nama_lengkap.length,
        ellipsis: true,
      },
      {
        title: "Keterangan",
        dataIndex: "keterangan",
        key: "keterangan",
        filteredValue: filteredInfo.keterangan || null,
        sorter: (a, b) => a.keterangan.length - b.keterangan.length,
        ellipsis: true,
      },
      {
        title: "Waktu",
        dataIndex: "created_at",
        key: "created_at",
        filteredValue: filteredInfo.created_at || null,
        sorter: (a, b) => a.created_at.length - b.created_at.length,
        ellipsis: true,
      },

      {
        align: "center",
        title: "Aksi",
        dataIndex: "id",
        key: "id",

        render: (dataIndex) => (
          <div className="aksiLogUser">
            <Row justify="center">
              <Col>
                <Tooltip
                  placement="bottom"
                  title="Hapus log"
                  color="#1f1f1f"
                  key="white"
                >
                  <Popconfirm
                    placement="left"
                    title={"Hapus log user ?"}
                    onConfirm={() => this.onDelete(dataIndex)}
                    okText="Hapus"
                    cancelText="Batal"
                  >
                    <Button className="btn-tolak">
                      <TrashFill />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
      },
    ];

    return (
      <div>
        <div
          className="logUser"
          style={{
            padding: "25px 30px",
            margin: "25px 30px",
            backgroundColor: "white",
          }}
        >
          <h1
            style={{
              fontSize: "x-large",
              color: "#001529",
              textTransform: "uppercase",
            }}
          >
            <strong>Log User</strong>
          </h1>
          <div
            style={{
              border: "1px solid #8C98AD",
              marginTop: "-10px",
              marginBottom: "15px",
            }}
          ></div>
          <Row style={{ marginBottom: 16 }} justify="space-between">
            <Col md={12}>
              <Space>
                <Button
                  className="btn-hapus-filter"
                  onClick={this.clearFilters}
                >
                  Hapus Filter
                </Button>
              </Space>
            </Col>
            <Col md={12}>
              <Input
                value={this.state.searchText}
                style={{ width: "100%" }}
                placeholder="Cari data log user disini"
                onChange={this.onChangeSearch}
              />
            </Col>
          </Row>
          <Table
            loading={this.state.loading}
            loadingIndicator={antIcon}
            columns={columns}
            scroll={{ x: 900, y: 1500 }}
            dataSource={this.state.daftarLogUser}
            onChange={this.handleChange}
            size="middle"
          />
        </div>
      </div>
    );
  }
}

export default LogUser;
