import React, { useContext, useState } from "react";
import myAxios from "../../myAxios";

import { Form, Input, Button, Row, Col, message } from "antd";
import { UserContext } from "../../context/UserContext";
import { Link, useHistory } from "react-router-dom";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import "./Login.css";
import LogoNav from "../../assets/logo/logo-ikdki.png";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const Login = () => {
  const [, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  let history = useHistory();
  const onFinish = (values) => {
    setLoading(true);
    const inputData = {
      email: values.email,
      password: values.password,
    };

    myAxios
      .post("login", inputData)
      .then((res) => {
        var nama = res.data.user.name;
        var email = res.data.user.email;
        var token = res.data.access_token;
        var role = res.data.user.role;
        var id_user = res.data.user.id;
        var id_dosen = null;

        if (role === "Dosen") {
          id_dosen = res.data.user.id_dosen;
        }

        var currentUser = {
          nama,
          email,
          token,
          role,
          id_user,
          id_dosen,
        };

        if (role === "Dosen") {
          history.push("/kartuID");
        } else {
          history.push("/");
        }
        setUser(currentUser);

        localStorage.setItem("user", JSON.stringify(currentUser));
        setLoading(false);
        message.success("Selamat Datang, " + nama + "!");
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.response.data.message);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="container">
      <div className="content">
        <Row justify="center">
          <Col md={10} style={{ marginTop: "90px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <br />
              <img
                alt="logo-ikdki.png"
                style={{ width: "300px", marginBottom: "20px" }}
                src={LogoNav}
              />
            </div>
            <Form
              {...layout}
              form={form}
              name="basic"
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                justify="center"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Masukan email yang valid!",
                    type: "email",
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder="  nama@gmail.com"
                />
              </Form.Item>

              <Form.Item
                justify="center"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Masukan kata sandi anda!",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  prefix={<KeyOutlined />}
                  placeholder="  Kata sandi"
                />
              </Form.Item>

              <Col style={{ margin: "auto", marginTop: "15px" }} md={16}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{ width: "100%" }}
                >
                  <b>Masuk</b>
                </Button>
              </Col>
              <Col style={{ marginTop: "5px" }}>
                <p style={{ textAlign: "center" }}>
                  Belum punya akun ?
                  <Link className="link" to="/registrasi">
                    <a
                      href="#registrasi"
                      style={{
                        fontWeight: "bolder",
                        marginLeft: "2px",
                        color: "#123160",
                      }}
                    >
                      Daftar disini!
                    </a>
                  </Link>
                </p>
              </Col>
              {/* <Col style={{ marginTop: "5px" }}>
                <p style={{ textAlign: "center" }}>
                  Info Login :
                  <p style={{ margin: 0 }}>email: dosen1@gmail.com</p>
                  <p style={{ margin: 0 }}>password: dosen1</p>
                </p>
              </Col> */}
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
