import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../../myAxios";
import { UserContext } from "../../../../context/UserContext";
import { useHistory, useParams } from "react-router-dom";
import { Form, Input, Button, message, Spin, Select } from "antd";
import moment from "moment";
import "./EditPenelitian.css";
import { LoadingOutlined } from "@ant-design/icons";
import CurrencyInput from "react-currency-input";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const EditPenelitianBiaya = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState(null);
  const [idTopik, setIdTopik] = useState(null);
  const { userId } = useParams();
  const [tempCek, setTempCek] = useState({
    budget: "0",
    sumber_biaya: "",
    actual: "0",
  });

  let history = useHistory();
  useEffect(() => {
    if (dataDetail === null) {
      getDataPenelitian();
    }
  });

  const onChange = (evt) => {
    setIdTopik(evt);
  };
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};

  const getDataPenelitian = () => {
    myAxios
      .get(`biayaPenelitianDosenEdit/${user.id_dosen}/${userId}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        form.setFieldsValue({
          topik: data.topik,
          sumber_biaya: data.sumber_biaya,
          budget: data.budget,
          actual: data.actual,
        });
        setIdTopik(data.id_penelitian);
        setDataDetail(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          if (
            err.response.data.message ===
            "Data Biaya Penelitian Tidak Ditemukan"
          ) {
            message.error(err.response.data.message);
            history.push(`/penelitianIndividu`);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    var tempBudget = values.budget.replace("Rp. ", "");
    var tempActual = values.actual.replace("Rp. ", "");

    let newObj = {
      id_dosen: user.id_dosen,
      id: dataDetail.id,
      id_penelitian: idTopik,
      sumber_biaya: values.sumber_biaya,
      budget: tempBudget.replaceAll(".", ""),
      actual: tempActual.replaceAll(".", ""),
    };

    myAxios
      .put(`biayaPenelitianDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Ubah data biaya penelitian berhasil!");
        setLoading(false);
        history.push(`/penelitianIndividu`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onReset = () => {
    var data = dataDetail;
    form.setFieldsValue({
      tanggal_mulai: moment(data.tanggal_mulai),
      bidang: data.bidang,
      topik: data.topik,
      sumber_biaya: data.sumber_biaya,
      budget: data.budget,
      actual: data.actual,
    });
    setTempCek({
      budget: data.budget,
      sumber_biaya: data.sumber_biaya,
      actual: data.actual,
    });
  };

  const onChangeValid = (evt) => {
    setTempCek({ ...tempCek, [evt.target.name]: evt.target.value });
  };

  return (
    <div
      className="editPenelitianBiaya"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Edit Biaya Penelitian</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>

      {!dataDetail && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {/* {!loading && cekKosong && (
        <h1 className="loading">
          <Empty />
        </h1>
      )} */}
      {dataDetail && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {dataDetail && (
            <Form.Item
              labelAlign="left"
              label="Topik"
              name="topik"
              rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
            >
              <Select
                style={{ width: "100%" }}
                autoComplete="off"
                showSearch
                placeholder="Cari topik"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
              >
                {dataDetail.kumpulanTopik.map((val, item) => (
                  <Option
                    key={val.id}
                    value={val.id}
                    style={{ fontWeight: "bold" }}
                  >
                    {val.topik}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            labelAlign="left"
            label="Sumber Biaya"
            name="sumber_biaya"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input name="sumber_biaya" onChange={onChangeValid} />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Budget"
            name="budget"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <CurrencyInput
              className="ant-input"
              name="budget"
              onChangeEvent={onChangeValid}
              thousandSeparator="."
              precision="0"
              prefix="Rp. "
            />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Actual"
            name="actual"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <CurrencyInput
              className="ant-input"
              name="actual"
              onChangeEvent={onChangeValid}
              thousandSeparator="."
              precision="0"
              prefix="Rp. "
            />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={onReset}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditPenelitianBiaya;
