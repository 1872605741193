import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../../myAxios";
import { UserContext } from "../../../../context/UserContext";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message, DatePicker } from "antd";
import moment from "moment";
import "./EditPenelitian.css";

const TambahPenelitian = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataDetail] = useState(null);

  let history = useHistory();
  useEffect(() => {});

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      id_dosen: user.id_dosen,
      tanggal_mulai: values.tanggal_mulai,
      bidang: values.bidang,
      topik: values.topik,
    };

    myAxios
      .post(`penelitianDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Tambah data Penelitian berhasil!");
        setLoading(false);
        history.push(`/penelitianIndividu`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onReset = () => {
    var data = dataDetail;
    form.setFieldsValue({
      tanggal_mulai: moment(data.tanggal_mulai),
      bidang: data.bidang,
      topik: data.topik,
      sumber_biaya: data.sumber_biaya,
      budget: data.budget,
      actual: data.actual,
    });
  };

  const onChangeTanggalMulai = (evt) => {};

  return (
    <div
      className="tambahPenelitian"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Tambah Penelitian</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      <Form
        layout="vertical"
        form={form}
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          labelAlign="left"
          label="Tanggal Mulai"
          name="tanggal_mulai"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <DatePicker
            style={{ width: "100%" }}
            placeholder="DD/MM/YYYY"
            format="DD/MM/YYYY"
            onChange={onChangeTanggalMulai}
            Date={(current) => {
              return current > moment();
            }}
          />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Bidang Penelitian"
          name="bidang"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Penelitian Topik"
          name="topik"
          rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ width: "fit-content", marginRight: "5px" }}
            danger
            onClick={onReset}
            loading={loading}
          >
            Reset
          </Button>
          <Button
            style={{ width: "fit-content" }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Simpan
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TambahPenelitian;
