import React from "react";
import { useState, useContext, useEffect } from "react";
import myAxios from "../../../myAxios";
import "./Home.css";
import { Row, Col, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { UserContext } from "../../../context/UserContext";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const Home = () => {
  const [user, setUser] = useContext(UserContext);
  const [dataKartu, setDataKartu] = useState(null);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    function handleResize() {
      setMatches(window.matchMedia("(min-width: 768px)").matches);
    }
    window.addEventListener("resize", handleResize);
    if (dataKartu === null) {
      getDataKartu();
    }
  });

  const getDataKartu = () => {
    myAxios
      .get(`dosen/${user.id_dosen}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setDataKartu(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Tidak Ditemukan") {
            message.error(err.response.data.message);
            // history.push(`/tabelPenelitian`);
          } else {
            message.error(err.response.data.message);
          }
        }
      });
  };

  return (
    <div
      className="calonAnggota"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Profil Dosen </strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {!dataKartu && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {dataKartu && (
        <div className="showCard">
          <div
            className="container-kartu"
            // ref={(el) => (this.componentRef = el)}
          >
            <Row
              justify={matches ? "start" : "center"}
              align="middle"
              style={{ height: "100%" }}
            >
              <Col xl={7} lg={11} md={11} sm={11}>
                <div className="kartuAnggota">
                  <div className="kerangka-foto">
                    <img
                      alt="foto.png"
                      src={`https://api-ikdki.arkin-dev.com/public/images/${dataKartu.foto}`}
                    ></img>
                    <div className="wrapper-text">
                      <p className="namaLengkap">{dataKartu.nama_gelar}</p>
                      <p className="noAnggota">{dataKartu.no_anggota}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={7} lg={11} md={11} sm={11}>
                <div className="kartuAnggotaBack">
                  <p className="tanggal-berlaku">{dataKartu.tahun_kartu}</p>
                </div>
              </Col>
              <Col xl={8} lg={24} md={24} sm={20}>
                <div className="contentProfilDosen">
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Nama Lengkap</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3> {dataKartu.nama_gelar}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Nomor Anggota</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3> {dataKartu.no_anggota}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Tempat Lahir</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3> {dataKartu.tempat_lahir}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Tanggal Lahir</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3>{moment(dataKartu.tanggal_lahir).format("ll")}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Institusi</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3> {dataKartu.institusi_asal}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Kota</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3> {dataKartu.kota_institusi}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Provinsi</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3> {dataKartu.provinsi_institusi}</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={10}>
                      <h3>
                        <b>Program Studi</b>
                      </h3>
                    </Col>
                    <Col md={10} xs={10}>
                      <h3> {dataKartu.prodi}</h3>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
