import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";

import NotFound from "../page/NotFound/NotFound";
import Login from "../page/Login/Login.jsx";
import Registrasi from "../page/RegistrasiPage/Registrasi.jsx";

import Profil from "../page/ProfilAdmin/Profil/Profil.jsx";
import TambahAdmin from "../page/ProfilAdmin/TambahAdmin/TambahAdmin.jsx";
import LihatAdmin from "../page/ProfilAdmin/LihatAdmin/LihatAdmin.jsx";
import EditAdmin from "../page/ProfilAdmin/EditAdmin/EditAdmin";

import CalonAnggota from "../page/CalonAnggota/CalonAnggota.jsx";

import AnggotaIKDKI from "../page/AnggotaIKDKI/AnggotaIKDKI.jsx";
import EditAnggota from "../page/AnggotaIKDKI/EditAnggota.jsx";

import TabelPengabdian from "../page/TabelPengabdian/TabelPengabdian.jsx";
import TabelPenelitian from "../page/TabelPenelitian/TabelPenelitian.jsx";
import TabelJurnal from "../page/TabelJurnal/TabelJurnal";
// import EditPenelitian from '../page/TabelPenelitian/EditPenelitian';
// import EditPengabdian from '../page/TabelPengabdian/EditPengabdian';

import KartuID from "../page/UserPage/ProfilSaya/KartuID.jsx";

import Home from "../page/UserPage/Home/Home";
import EditDosen from "../page/UserPage/ProfilSaya/EditDosen";
import KonfirmasiEdit from "../page/KonfirmasiEditUser/KonfirmasiEdit";
import Detail from "../page/KonfirmasiEditUser/Detail/Detail";
import UbahKataSandi from "../page/UserPage/ProfilSaya/UbahKataSandi";

import TabelPengabdianPublic from "../page/UserPage/Abmas/Public/TabelPengabdian.jsx";
import TabelPenelitianPublic from "../page/UserPage/Penelitian/Public/TabelPenelitian";
import TabelJurnalPublic from "../page/UserPage/Jurnal/Public/TabelJurnal.jsx";
import TabelPengajaranPublic from "../page/UserPage/Pengajaran/Public/TabelPengajaran.jsx";

import TabelPenelitianIndividu from "../page/UserPage/Penelitian/Individu/TabelPenelitianIndividu.jsx";
import EditPenelitianIndividu from "../page/UserPage/Penelitian/Individu/EditPenelitian.jsx";
import EditPenelitianBiaya from "../page/UserPage/Penelitian/Individu/EditPenelitianBiaya.jsx";
import TambahPenelitian from "../page/UserPage/Penelitian/Individu/TambahPenelitian";
import TambahBiaya from "../page/UserPage/Penelitian/Individu/TambahBiaya";

import TabelJurnalIndividu from "../page/UserPage/Jurnal/Individu/TabelJurnalIndividu.jsx";
import EditJurnalIndividu from "../page/UserPage/Jurnal/Individu/EditJurnal.jsx";
import TambahJurnal from "../page/UserPage/Jurnal/Individu/TambahJurnal.jsx";

import TabelPengajaranIndividu from "../page/UserPage/Pengajaran/Individu/TabelPengajaranIndividu.jsx";
import EditPengajaranIndividu from "../page/UserPage/Pengajaran/Individu/EditPengajaran.jsx";
import TambahPengajaran from "../page/UserPage/Pengajaran/Individu/TambahPengajaran.jsx";

import TabelPengabdianIndividu from "../page/UserPage/Abmas/Individu/TabelPengabdianIndividu.jsx";
import EditPengabdianIndividu from "../page/UserPage/Abmas/Individu/EditPengabdian.jsx";
import EditPengabdianBiaya from "../page/UserPage/Abmas/Individu/EditPengabdianBiaya.jsx";
import TambahPengabdian from "../page/UserPage/Abmas/Individu/TambahPengabdian";
import TambahBiayaPengabdian from "../page/UserPage/Abmas/Individu/TambahBiaya";

import LogUser from "../page/LogUser/LogUser";
// import Info from '../page/Info/Info';
import Reporting from "../page/Reporting/Reporting.jsx";
import Choropleth from "../page/Reporting/Choropleth";
import TabelPengajaran from "../page/TabelPengajaran/TabelPengajaran";
import Institusi from "../page/TambahInstitusi/Institusi";
import TambahInstitusi from "../page/TambahInstitusi/TambahInstitusi";
import EditInstitusi from "../page/TambahInstitusi/EditInstitusi";
import Pencarian from "../page/UserPage/Pencarian/Pencarian";

const ContentCustom = () => {
  const [user] = useContext(UserContext);

  const AuthRoute = ({ ...props }) => {
    if (user && user.role === "Dosen") {
      return <Redirect to="/home" />;
    } else if (user && user.role !== "Dosen") {
      return <Redirect to="/" />;
    } else {
      return <Route {...props} />;
    }
  };

  const PrivateRoute = ({ ...props }) => {
    if (!user) {
      return <Redirect to="/login" />;
    } else {
      return <Route {...props} />;
    }
  };

  const SuperAdminRoute = ({ ...props }) => {
    if (!user) {
      return <Redirect to="/login" />;
    } else if (user.role === "Super Admin") {
      return <Route {...props} />;
    } else {
      return <Redirect to="/home" />;
    }
  };

  const AdminRoute = ({ ...props }) => {
    if (!user) {
      return <Redirect to="/login" />;
    } else if (user.role === "Admin" || user.role === "Super Admin") {
      return <Route {...props} />;
    } else {
      return <Redirect to="/home" />;
    }
  };

  const DosenRoute = ({ ...props }) => {
    if (!user) {
      return <Redirect to="/login" />;
    } else if (user.role === "Dosen") {
      return <Route {...props} />;
    } else {
      return <Redirect to="/" />;
    }
  };

  return (
    <div>
      <Switch>
        <AuthRoute exact path="/login" component={Login} />
        <Route exact path="/registrasi" component={Registrasi} />
        <AdminRoute exact path="/profil" component={Profil} />
        <SuperAdminRoute exact path="/tambahAdmin" component={TambahAdmin} />
        <SuperAdminRoute exact path="/lihatAdmin" component={LihatAdmin} />
        <AdminRoute exact path="/editAdmin/:userId" component={EditAdmin} />ß
        <AdminRoute exact path="/" component={CalonAnggota} />
        <AdminRoute exact path="/anggotaIKDKI" component={AnggotaIKDKI} />
        <AdminRoute exact path="/editAnggota/:userId" component={EditAnggota} />
        <AdminRoute exact path="/konfirmasiEdit" component={KonfirmasiEdit} />
        <AdminRoute exact path="/tampilDetail/:userId" component={Detail} />
        <AdminRoute exact path="/tabelPengabdian" component={TabelPengabdian} />
        <PrivateRoute exact path="/reporting" component={Reporting} />
        <AdminRoute exact path="/institusi" component={Institusi} />
        <AdminRoute exact path="/tambahInstitusi" component={TambahInstitusi} />
        <AdminRoute
          exact
          path="/editInstitusi/:idInstitusi"
          component={EditInstitusi}
        />
        {/* <AdminRoute
          exact
          path="/editPengabdian/:userId/:id_biaya"
          component={EditPengabdian}
        /> */}
        <AdminRoute exact path="/tabelPenelitian" component={TabelPenelitian} />
        {/* <AdminRoute
          exact
          path="/editPenelitian/:userId/:id_biaya"
          component={EditPenelitian}
        /> */}
        <AdminRoute exact path="/tabelPublikasi" component={TabelJurnal} />
        <AdminRoute exact path="/tabelPengajaran" component={TabelPengajaran} />
        <AdminRoute exact path="/logUser" component={LogUser} />
        {/* <AdminRoute exact path='/info' component={Info} /> */}
        <AdminRoute exact path="/heat" component={Choropleth} />
        <DosenRoute exact path="/home" component={Home} />
        <DosenRoute exact path="/kartuID" component={KartuID} />
        <DosenRoute exact path="/editDosen" component={EditDosen} />
        <DosenRoute exact path="/ubahKataSandi" component={UbahKataSandi} />
        <DosenRoute exact path="/pencarian" component={Pencarian} />
        {/* start of penelitian  */}
        <DosenRoute
          exact
          path="/publicPenelitian"
          component={TabelPenelitianPublic}
        />
        <DosenRoute
          exact
          path="/penelitianIndividu"
          component={TabelPenelitianIndividu}
        />
        <DosenRoute
          exact
          path="/editPenelitianIndividu/:userId"
          component={EditPenelitianIndividu}
        />
        <DosenRoute
          exact
          path="/tambahPenelitian"
          component={TambahPenelitian}
        />
        <DosenRoute exact path="/tambahBiaya" component={TambahBiaya} />
        <DosenRoute
          exact
          path="/editBiayaPenelitian/:userId"
          component={EditPenelitianBiaya}
        />
        <DosenRoute
          exact
          path="/pengabdianIndividu"
          component={TabelPengabdianIndividu}
        />
        {/* end of penelitian  */}
        {/* start of pengabdian  */}
        <DosenRoute
          exact
          path="/publicPengabdian"
          component={TabelPengabdianPublic}
        />
        <DosenRoute
          exact
          path="/pengabdianIndividu"
          component={TabelPengabdianIndividu}
        />
        <DosenRoute
          exact
          path="/editPengabdianIndividu/:userId"
          component={EditPengabdianIndividu}
        />
        <DosenRoute
          exact
          path="/tambahPengabdian"
          component={TambahPengabdian}
        />
        <DosenRoute
          exact
          path="/tambahBiayaPengabdian"
          component={TambahBiayaPengabdian}
        />
        <DosenRoute
          exact
          path="/editBiayaPengabdian/:userId"
          component={EditPengabdianBiaya}
        />
        <DosenRoute
          exact
          path="/pengabdianIndividu"
          component={TabelPengabdianIndividu}
        />
        {/* end of pengabdian  */}
        {/* start of publikasi  */}
        <DosenRoute
          exact
          path="/publicPublikasi"
          component={TabelJurnalPublic}
        />
        <DosenRoute
          exact
          path="/publikasiIndividu"
          component={TabelJurnalIndividu}
        />
        <DosenRoute
          exact
          path="/editPublikasi/:userId"
          component={EditJurnalIndividu}
        />
        <DosenRoute exact path="/tambahPublikasi" component={TambahJurnal} />
        {/* end of publikasi  */}
        {/* start of pengajaran  */}
        <DosenRoute
          exact
          path="/publicPengajaran"
          component={TabelPengajaranPublic}
        />
        <DosenRoute
          exact
          path="/pengajaranIndividu"
          component={TabelPengajaranIndividu}
        />
        <DosenRoute
          exact
          path="/editPengajaran/:userId"
          component={EditPengajaranIndividu}
        />
        <DosenRoute
          exact
          path="/tambahPengajaran"
          component={TambahPengajaran}
        />
        {/* end of pengajaran  */}
        <PrivateRoute component={NotFound}></PrivateRoute>
      </Switch>
    </div>
  );
};

export default ContentCustom;
