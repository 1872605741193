import React from "react";
import { useState, useContext, useEffect } from "react";
import myAxios from "../../myAxios";
// import "./NotFound.css";
import { Row, Col, Button, message, Spin, Modal, Image } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { UserContext } from "../../context/UserContext";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const NotFound = () => {
  const [user, setUser] = useContext(UserContext);
  const [dataKartu, setDataKartu] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [img, setImg] = useState(null);
  const [imgApi, setImgApi] = useState(null);
  const [imgFromApi, setImgFromApi] = useState(null);

  useEffect(() => {
    if (dataKartu === null) {
      //   getDataKartu();
    }
  });

  const getDataKartu = () => {
    myAxios
      .get(`dosen/${user.id_dosen}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data.gelar_depan !== "-") {
          data.nama_lengkap = data.gelar_depan + " " + data.nama_lengkap;
        }
        if (data.gelar_belakang !== "-") {
          data.nama_lengkap = data.nama_lengkap + " " + data.gelar_belakang;
        }
        setDataKartu(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
        } else {
          if (err.response.data.message === "Data Tidak Ditemukan") {
            message.error(err.response.data.message);
            // history.push(`/tabelPenelitian`);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };
  const onSubmitUnduh = () => {};
  const onFinishUpdate = () => {
    if (!img) {
      message.error("Silahkan unggah foto!");
    } else {
      let formData = new FormData();
      formData.append("id", user.id_dosen);
      formData.append("foto", imgApi);
      setLoading(true);
      myAxios
        .post(`updatePhoto`, formData, {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        })
        .then((res) => {
          message.success("Ubah Foto Berhasil");
          getDataKartu();
          setLoading(false);
          setVisible(false);
          setImg(null);
        })
        .catch((err) => {
          if (err.response.data.message === "Unauthenticated.") {
            message.error("Sesi anda telah habis!");
            localStorage.removeItem("user");
          } else {
            if (err.response.data.message === "Data Tidak Ditemukan") {
              message.error(err.response.data.message);
              // history.push(`/tabelPenelitian`);
            } else {
              message.error(err.response.data.message);
            }
          }
          setLoading(false);
        });
    }
  };

  const onUbahFoto = () => {
    setVisible(true);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setImg(URL.createObjectURL(img));
      setImgApi(img);
    }
  };

  const onCetakCard = () => {};
  return (
    <div
      className="calonAnggota"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>NOT FOUND 404 </strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
    </div>
  );
};

export default NotFound;
