import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../../myAxios";
import { UserContext } from "../../../../context/UserContext";
import { useHistory, useParams } from "react-router-dom";
import { Form, Input, Button, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./EditPengajaran.css";

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const EditPengajaran = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [dataDetail, setDataDetail] = useState(null);

  const { userId } = useParams();

  let history = useHistory();
  useEffect(() => {
    if (dataDetail === null) {
      getDataPengajaran();
    }
  });

  const getDataPengajaran = () => {
    myAxios
      .get(`pengajaranDosenEdit/${userId}/${user.id_dosen}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        form.setFieldsValue({
          mata_kuliah: data.mata_kuliah,
        });

        setDataDetail(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Pengajaran Tidak Ditemukan") {
            message.error(err.response.data.message);
            history.push(`/pengajaranIndividu`);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    let newObj = {
      id: userId,
      id_dosen: user.id_dosen,
      mata_kuliah: values.mata_kuliah,
    };

    myAxios
      .put(`pengajaranDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Ubah data pengajaran berhasil!");
        setLoading(false);
        history.push(`/pengajaranIndividu`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {};

  const onReset = () => {
    var data = dataDetail;
    form.setFieldsValue({
      mata_kuliah: data.mata_kuliah,
    });
  };

  return (
    <div
      className="editPengajaran"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Edit Pengajaran</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {!dataDetail && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {dataDetail && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            labelAlign="left"
            label="Mata Kuliah"
            name="mata_kuliah"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={onReset}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditPengajaran;
