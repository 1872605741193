import React from 'react';
import myAxios from '../../../myAxios.js';
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { UserContext } from '../../../context/UserContext.jsx';

import './LihatAdmin.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};

class LihatAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: '',
      searchedColumn: '',
      loading: false,
      loadingAct: false,
      daftarAdmin: null,
      tempDaftarAdmin: null,
      user: null,
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    if (this.state.daftarAdmin === null) {
      this.getAdmin();
    }
  }

  getAdmin = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`admin`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          this.setState({
            daftarAdmin: data,
            tempDaftarAdmin: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarAdmin: data,
            tempDaftarAdmin: data,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({
      filteredInfo: null,
      searchText: '',
      daftarAdmin: this.state.tempDaftarAdmin,
    });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearch = (evt) => {
    this.setState({ searchText: evt.target.value });
    if (this.state.tempDaftarAdmin) {
      if (evt.target.value === '') {
        this.setState({ daftarAdmin: this.state.tempDaftarAdmin });
      } else {
        this.setState({
          daftarAdmin: this.state.tempDaftarAdmin.filter((i) => {
            return (
              i.name.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.email.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.role.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  DeleteAdmin = (index) => {
    const user = this.context[0];

    this.setState({ loadingAct: true });
    myAxios
      .delete(`hapusAdmin/${index}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        let filter = this.state.daftarAdmin.filter((el) => {
          return el.id !== index;
        });

        this.setState({
          daftarAdmin: filter,
          tempDaftarAdmin: filter,
          loadingAct: false,
        });
        message.success('Data admin berhasil dihapus!');
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error('Gagal Menghapus : ' + err.response.data.message);
        }
        this.setState({ loadingAct: false });
      });
  };

  EditAdmin(index) {
    this.setState({ loadingAct: true });
    window.location.pathname = `/editAdmin/${index}`;
  }

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const user = this.context[0];
    const columns = [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        filteredValue: filteredInfo.nomor || null,
        sorter: (a, b) => a.nomor - b.nomor,
        ellipsis: true,
        width: '7%',
      },
      {
        title: 'Nama',
        dataIndex: 'name',
        key: 'name',
        filteredValue: filteredInfo.name || null,
        sorter: (a, b) => a.name.length - b.name.length,
        ellipsis: true,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        filteredValue: filteredInfo.email || null,
        sorter: (a, b) => a.email.length - b.email.length,
        ellipsis: true,
      },
      {
        title: 'Akses',
        dataIndex: 'role',
        key: 'role',
        filteredValue: filteredInfo.role || null,
        sorter: (a, b) => a.role.length - b.role.length,
        ellipsis: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filteredValue: filteredInfo.status || null,
        sorter: (a, b) => a.status.length - b.status.length,
        ellipsis: true,
      },
      {
        align: 'center',
        title: 'Aksi',
        dataIndex: 'id',
        key: 'id',

        render: (dataIndex) => (
          <div>
            {user.role === 'Super Admin' && (
              <div className='aksiAdmin'>
                {!this.state.loadingAct && (
                  <Row justify='space-around'>
                    <Col>
                      <Tooltip
                        placement='bottom'
                        title='Hapus data admin'
                        color='#1f1f1f'
                        key='white'>
                        <Popconfirm
                          placement='left'
                          title={'Apakah anda yakin ingin menghapus ?'}
                          onConfirm={() => this.DeleteAdmin(dataIndex)}
                          okText='Hapus'
                          cancelText='Batal'>
                          <Button className='btn-delete'>
                            <TrashFill />
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </Col>
                    <Col>
                      <Tooltip
                        placement='bottom'
                        title='Edit data admin'
                        color='#1f1f1f'
                        key='white'>
                        <Button
                          className='btn-edit'
                          onClick={() => this.EditAdmin(dataIndex)}>
                          <PencilFill />
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                )}
                {this.state.loadingAct && <Spin indicator={antIcon} />}
              </div>
            )}
          </div>
        ),
      },
    ];
    return (
      <div
        className='lihatAdmin'
        style={{
          padding: '25px 30px',
          margin: '25px 30px',
          backgroundColor: 'white',
        }}>
        <h1
          style={{
            fontSize: 'x-large',
            color: '#001529',
            textTransform: 'uppercase',
          }}>
          <strong>Daftar Admin</strong>
        </h1>
        <div
          style={{
            border: '1px solid #8C98AD',
            marginTop: '-10px',
            marginBottom: '15px',
          }}></div>
        <Row style={{ marginBottom: 16 }} justify='space-between'>
          <Col md={12}>
            <Button className='btn-hapus-filter' onClick={this.clearFilters}>
              Hapus Filter
            </Button>
          </Col>
          <Col md={12}>
            <Input
              value={this.state.searchText}
              style={{ width: '100%' }}
              placeholder='Cari data admin disini'
              onChange={this.onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          columns={columns}
          scroll={{ x: 900, y: 1500 }}
          dataSource={this.state.daftarAdmin}
          onChange={this.handleChange}
          size='middle'
        />
      </div>
    );
  }
}

export default LihatAdmin;
