import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../../myAxios";
import { UserContext } from "../../../../context/UserContext";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, message, DatePicker, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import "./EditPengabdian.css";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const TambahPengabdian = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [tempatPengabdian, setTempatPengabdian] = useState(null);

  let history = useHistory();
  useEffect(() => {
    if (tempatPengabdian === null) {
      getTempatPengabdian();
    }
  });

  const onFinish = (values) => {
    setLoading(true);

    let newObj = {
      id_dosen: user.id_dosen,
      tanggal_mulai: values.tanggal_mulai,
      regencies_id: values.tempat_pengabdian,
      topik: values.topik,
    };

    myAxios
      .post(`pengabdianDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        message.success("Tambah data pengabdian berhasil!");
        setLoading(false);
        history.push(`/pengabdianIndividu`);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const getTempatPengabdian = () => {
    myAxios
      .get(`tempatPengabdianDosen`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        setTempatPengabdian(data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const onChange = () => {};
  const onFocus = () => {};
  const onBlur = () => {};
  const onSearch = () => {};
  const onFinishFailed = (errorInfo) => {};

  const onReset = () => {
    form.resetFields();
  };

  const onChangeTanggalMulai = (evt) => {};

  return (
    <div
      className="tambahPengabdian"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Tambah Pengabdian</strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      {!tempatPengabdian && (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mengambil data..</p>
        </h1>
      )}
      {tempatPengabdian && (
        <Form
          layout="vertical"
          form={form}
          name="basic"
          initialValues={{ remember: false }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            labelAlign="left"
            label="Tanggal Mulai"
            name="tanggal_mulai"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              onChange={onChangeTanggalMulai}
              Date={(current) => {
                return current > moment();
              }}
            />
          </Form.Item>

          <Form.Item
            labelAlign="left"
            label="Pengabdian Topik"
            name="topik"
            rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
          >
            <Input />
          </Form.Item>
          {tempatPengabdian && (
            <Form.Item
              labelAlign="left"
              label="Tempat Pengabdian"
              name="tempat_pengabdian"
              rules={[{ required: true, message: "Bagian ini wajib diisi" }]}
            >
              <Select
                style={{ width: "100%" }}
                autoComplete="off"
                showSearch
                placeholder="Cari tempat pengabdian"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
              >
                {tempatPengabdian.map((val, item) => (
                  <Option
                    key={val.id}
                    value={val.id}
                    style={{ fontWeight: "bold" }}
                  >
                    {val.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              style={{ width: "fit-content", marginRight: "5px" }}
              danger
              onClick={onReset}
              loading={loading}
            >
              Reset
            </Button>
            <Button
              style={{ width: "fit-content" }}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Simpan
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default TambahPengabdian;
