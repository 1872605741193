import React from "react";
import myAxios from "../../myAxios";
import moment from "moment";
import "./CalonAnggota.css";
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
  Modal,
  Image,
  Space,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CheckLg, XLg, InfoLg } from "react-bootstrap-icons";
import { UserContext } from "../../context/UserContext.jsx";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class CalonAnggota extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchedColumn: "",
      loading: false,
      daftarCalonAnggota: null,
      tempdaftarCalonAnggota: null,
      visible: false,
      dataDetail: null,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarCalonAnggota === null) {
      this.getDataAnggota();
    }
  }

  getDataAnggota = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`calonDosen`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data !== null) {
          // var temp = data.map((el) => {
          //   if (el.gelar_depan !== "-") {
          //     el.nama_lengkap = el.gelar_depan + " " + el.nama_lengkap;
          //   }
          //   if (el.gelar_belakang !== "-") {
          //     el.nama_lengkap = el.nama_lengkap + " " + el.gelar_belakang;
          //   }
          // });
          this.setState({
            daftarCalonAnggota: data,
            tempdaftarCalonAnggota: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarCalonAnggota: null,
            tempdaftarCalonAnggota: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({
      sortedInfo: null,
      filteredInfo: null,
      searchText: "",
      daftarCalonAnggota: this.state.tempdaftarCalonAnggota,
    });
  };

  onChangeSearch = (evt) => {
    this.setState({ searchText: evt.target.value });
    if (this.state.tempdaftarCalonAnggota) {
      if (evt.target.value === "") {
        this.setState({
          daftarCalonAnggota: this.state.tempdaftarCalonAnggota,
        });
      } else {
        this.setState({
          daftarCalonAnggota: this.state.tempdaftarCalonAnggota.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.institusi_asal
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.jenjang_akademik
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.provinsi_institusi
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.kota_institusi
                .toLowerCase()
                .includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  onTerima = (param) => {
    const user = this.context[0];
    myAxios
      .put(`confirmDosen/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        let filter = this.state.daftarCalonAnggota.filter((el) => {
          return el.id !== param;
        });
        this.setState({
          daftarCalonAnggota: filter,
          tempdaftarCalonAnggota: filter,
        });

        message.success("Dosen berhasil diterima!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onTolak = (param) => {
    const user = this.context[0];
    myAxios
      .put(`tolakDosen/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        this.getDataAnggota();
        message.success("Dosen berhasil ditolak!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onApproveAll = () => {
    const user = this.context[0];
    this.setState({ loading: tableLoading });
    let newObj = {
      data: this.state.tempdaftarCalonAnggota,
    };
    myAxios
      .post(`confirmAllDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        this.getDataAnggota();
        this.setState({ loading: false });
        message.success("Semua dosen berhasil diterima!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loading: false });
      });
  };

  onDetail = (index) => {
    let filter = this.state.tempdaftarCalonAnggota.filter((el) => {
      return el.id === index;
    });
    this.setState({ dataDetail: filter[0], visible: true });
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: "7%",
      },
      {
        title: "Nama Anggota",
        dataIndex: "nama_gelar",
        key: "nama_gelar",
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: "Universitas",
        dataIndex: "institusi_asal",
        key: "institusi_asal",
        filteredValue: filteredInfo.institusi_asal || null,
        sorter: (a, b) => a.institusi_asal.length - b.institusi_asal.length,
        ellipsis: true,
      },
      {
        title: "Provinsi",
        dataIndex: "provinsi_institusi",
        key: "provinsi_institusi",
        filteredValue: filteredInfo.provinsi_institusi || null,
        sorter: (a, b) =>
          a.provinsi_institusi.length - b.provinsi_institusi.length,
        ellipsis: true,
      },
      {
        title: "Detail",
        dataIndex: "jenjang_akademik",
        key: "jenjang_akademik",
        filteredValue: filteredInfo.jenjang_akademik || null,
        sorter: (a, b) => a.jenjang_akademik.length - b.jenjang_akademik.length,
        ellipsis: true,
      },
      {
        align: "center",
        title: "Aksi",
        dataIndex: "id",
        key: "id",

        render: (dataIndex) => (
          <div className="aksiCalonAnggota">
            {this.state.dataDetail && (
              <Modal
                width={500}
                title="Detail Calon Anggota"
                visible={this.state.visible}
                onCancel={() => this.setState({ visible: false })}
                footer={[]}
              >
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Nama Lengkap</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.nama_gelar}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Tempat Lahir</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.tempat_lahir}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Tanggal Lahir</b>
                    </p>
                  </Col>
                  <Col md={12}>
                    : {moment(this.state.dataDetail.tanggal_lahir).format("LL")}
                  </Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Institusi Asal</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.institusi_asal}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Kota Institusi</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.kota_institusi}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Provinsi Institusi</b>
                    </p>
                  </Col>
                  <Col md={12} style={{ textTransform: "capitalize" }}>
                    : {this.state.dataDetail.provinsi_institusi}
                  </Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Program Studi</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.prodi}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Jenjang Mengajar</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.jenjang}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Pendidikan Terakhir</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.pendidikan}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Jenjang Akademik</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.jenjang_akademik}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Bidang Keahlian 1</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.keahlian_1}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Bidang Keahlian 2</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.keahlian_2}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Bidang Keahlian 3</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.keahlian_3}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>NIDN</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.nidn}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Nomor Handphone</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.no_hp}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Foto</b>
                    </p>
                  </Col>
                  <Col md={12}>
                    <Image
                      style={{
                        width: "75px",
                        height: "75px",
                        objectFit: "cover",
                      }}
                      src={`https://api-ikdki.arkin-dev.com/public/images/${this.state.dataDetail.foto}`}
                    />
                  </Col>
                </Row>
              </Modal>
            )}
            <Row justify="space-around">
              <Col>
                <Tooltip
                  placement="bottom"
                  title="Terima dosen"
                  color="#1f1f1f"
                  key="white"
                >
                  <Popconfirm
                    placement="left"
                    title={"Terima konfirmasi dosen ?"}
                    onConfirm={() => this.onTerima(dataIndex)}
                    okText="Terima"
                    cancelText="Batal"
                  >
                    <Button className="btn-terima">
                      <CheckLg />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  placement="bottom"
                  title="Tolak dosen"
                  color="#1f1f1f"
                  key="white"
                >
                  <Popconfirm
                    placement="left"
                    title={"Tolak konfirmasi dosen ?"}
                    onConfirm={() => this.onTolak(dataIndex)}
                    okText="Tolak"
                    cancelText="Batal"
                  >
                    <Button className="btn-tolak">
                      <XLg />
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  placement="bottom"
                  title="Detail dosen"
                  color="#1f1f1f"
                  key="white"
                >
                  <Button
                    className="btn-info"
                    onClick={() => this.onDetail(dataIndex)}
                  >
                    <InfoLg />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <div
        className="calonAnggota"
        style={{
          padding: "25px 30px",
          margin: "25px 30px",
          backgroundColor: "white",
        }}
      >
        <h1
          style={{
            fontSize: "x-large",
            color: "#001529",
            textTransform: "uppercase",
          }}
        >
          <strong>Calon Anggota</strong>
        </h1>
        <div
          style={{
            border: "1px solid #8C98AD",
            marginTop: "-10px",
            marginBottom: "15px",
          }}
        ></div>
        <Row style={{ marginBottom: 16 }} justify="space-between">
          <Col md={12}>
            <Space>
              <Button className="btn-hapus-filter" onClick={this.clearFilters}>
                Hapus Filter
              </Button>
              <Button className="btn-hapus-filter" onClick={this.onApproveAll}>
                Terima Semua
              </Button>
            </Space>
          </Col>
          <Col md={12}>
            <Input
              value={this.state.searchText}
              style={{ width: "100%" }}
              placeholder="Cari data calon anggota disini"
              onChange={this.onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          columns={columns}
          scroll={{ x: 900, y: 1500 }}
          dataSource={this.state.daftarCalonAnggota}
          onChange={this.handleChange}
          size="middle"
        />
      </div>
    );
  }
}

export default CalonAnggota;
