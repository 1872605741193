import React from 'react';
import myAxios from '../../../../myAxios';
import moment from 'moment';
import './TabelPengabdianIndividu.css';
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
  Space,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { PencilFill, TrashFill } from 'react-bootstrap-icons';
import { UserContext } from '../../../../context/UserContext.jsx';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class TabelBiayaPengabdian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: '',
      searchedColumn: '',
      loading: false,
      daftarBiaya: null,
      tempdaftarBiaya: null,
      visible: false,
      dataDetail: null,
      topik: null,
      loadingAct: false,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarBiaya === null) {
      this.getBiaya();
    }

    if (this.state.topik === null) {
      this.getTopik();
    }
  }

  getTopik = () => {
    const user = this.context[0];
    myAxios
      .get(`topikAbmas/${user.id_dosen}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        this.setState({ topik: res.data.data });
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
      });
  };

  getBiaya = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`biayaAbmasDosen/${user.id_dosen}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          data.map((el) => {
            if (el.budget !== '-') {
              el.budgetNumber = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
              }).format(el.budget);

              el.budgetFilter = Number(el.budget);
            } else el.budgetNumber = '-';

            if (el.actual !== '-') {
              el.actualNumber = new Intl.NumberFormat('id-ID', {
                style: 'currency',
                currency: 'IDR',
              }).format(el.actual);
              el.actualFilter = Number(el.actual);
            } else el.actualNumber = '-';
            el.tanggal_mulai = moment(el.tanggal_mulai).format('LL');
          });

          this.setState({
            daftarBiaya: data,
            tempdaftarBiaya: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarBiaya: null,
            tempdaftarBiaya: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearchBiaya = (evt) => {
    if (this.state.daftarBiaya) {
      if (evt.target.value === '') {
        this.setState({ daftarBiaya: this.state.tempdaftarBiaya });
      } else {
        this.setState({
          daftarBiaya: this.state.tempdaftarBiaya.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.topik.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.sumber_biaya
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.budget.toLowerCase().includes(evt.target.value.toLowerCase()) ||
              i.actual.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  onEditBiaya = (index) => {
    this.setState({ loadingAct: true });
    window.location.pathname = `/editBiayaPengabdian/${index}`;
  };
  onTambahBiaya = async () => {
    if (this.state.topik !== null) {
      this.setState({
        loading: tableLoading,
      });
      window.location.pathname = `/tambahBiayaPengabdian`;
    } else message.error('Anda tidak memiliki topik!');
  };

  onDelete = (index) => {
    this.setState({ loadingAct: true });
    const user = this.context[0];
    let newObj = {
      id_dosen: user.id_dosen,
      id: index,
    };
    myAxios
      .put(`deleteBiayaAbmasDosen`, newObj, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        this.getBiaya();
        this.setState({
          loadingAct: false,
        });

        message.success(res.data.message);
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loadingAct: false,
        });
      });
  };
  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columnsBiaya = [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: '7%',
      },
      {
        title: 'Nama Anggota',
        dataIndex: 'nama_gelar',
        key: 'nama_gelar',
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },

      {
        title: 'Topik',
        dataIndex: 'topik',
        key: 'topik',
        filteredValue: filteredInfo.topik || null,
        sorter: (a, b) => a.topik.length - b.topik.length,
        ellipsis: true,
      },
      {
        title: 'Sumber Biaya',
        dataIndex: 'sumber_biaya',
        key: 'sumber_biaya',
        filteredValue: filteredInfo.sumber_biaya || null,
        sorter: (a, b) => a.sumber_biaya.length - b.sumber_biaya.length,
        ellipsis: true,
      },
      {
        title: 'Budget',
        dataIndex: 'budgetNumber',
        key: 'budget',
        filteredValue: filteredInfo.budgetFilter || null,
        sorter: (a, b) => a.budgetFilter - b.budgetFilter,
        ellipsis: true,
      },
      {
        title: 'Actual',
        dataIndex: 'actualNumber',
        key: 'actual',
        filteredValue: filteredInfo.actualFilter || null,
        sorter: (a, b) => a.actualFilter - b.actualFilter,
        ellipsis: true,
      },
      {
        align: 'center',
        title: 'Aksi',
        dataIndex: 'id',
        key: 'id',

        render: (dataIndex) => (
          <div className='aksiPengabdian'>
            {!this.state.loadingAct && (
              <Row justify='space-around'>
                <Col style={{ marginTop: '2px' }}>
                  <Tooltip
                    placement='bottom'
                    title='Edit biaya'
                    color='#1f1f1f'
                    key='white'>
                    <Button
                      className='btn-edit'
                      onClick={() => this.onEditBiaya(dataIndex)}>
                      <PencilFill />
                    </Button>
                  </Tooltip>
                </Col>
                <Col style={{ marginTop: '2px' }}>
                  <Tooltip
                    placement='bottom'
                    title='Hapus biaya'
                    color='#1f1f1f'
                    key='white'>
                    <Popconfirm
                      placement='left'
                      title={'Hapus biaya ?'}
                      onConfirm={() => this.onDelete(dataIndex)}
                      okText='Hapus'
                      cancelText='Batal'>
                      <Button className='btn-tolak'>
                        <TrashFill />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                </Col>
              </Row>
            )}
            {this.state.loadingAct && <Spin indicator={antIcon} />}
          </div>
        ),
      },
    ];
    return (
      <div
        className='pengabdian'
        style={{
          padding: '25px 30px',
          margin: '25px 30px',
          backgroundColor: 'white',
        }}>
        <h1
          style={{
            fontSize: 'x-large',
            color: '#001529',
            textTransform: 'uppercase',
          }}>
          <strong>Biaya Pengabdian</strong>
        </h1>
        <div
          style={{
            border: '1px solid #8C98AD',
            marginTop: '-10px',
            marginBottom: '15px',
          }}></div>
        <Row style={{ marginBottom: 16 }} justify='space-between'>
          <Col md={4}>
            <Space>
              <Button className='btn-hapus-filter' onClick={this.clearFilters}>
                Hapus Filter
              </Button>
              <Button className='btn-hapus-filter' onClick={this.onTambahBiaya}>
                Tambah Biaya
              </Button>
            </Space>
          </Col>

          <Col md={12} xs={24}>
            <Input
              style={{ width: '100%' }}
              placeholder='Cari data biaya disini'
              onChange={this.onChangeSearchBiaya}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          scroll={{ x: 900, y: 1500 }}
          columns={columnsBiaya}
          dataSource={this.state.daftarBiaya}
          onChange={this.handleChange}
          size='middle'
        />
      </div>
    );
  }
}

export default TabelBiayaPengabdian;
