import React from "react";
import myAxios from "../../myAxios";
import moment from "moment";
import "./KonfirmasiEdit.css";
import {
  Table,
  Button,
  Input,
  Tooltip,
  Row,
  Col,
  message,
  Spin,
  Modal,
  Image,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { InfoLg } from "react-bootstrap-icons";
import { UserContext } from "../../context/UserContext.jsx";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class KonfirmasiEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchedColumn: "",
      loading: false,
      daftarKonfirmasiEdit: null,
      tempdaftarKonfirmasiEdit: null,
      visible: false,
      dataDetail: null,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarKonfirmasiEdit === null) {
      this.getDataKonfirmasi();
    }
  }

  getDataKonfirmasi = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`dosenEdit`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;

        if (data !== null) {
          // var temp = data.map((el) => {
          //   if (el.gelar_depan !== "-") {
          //     el.nama_lengkap = el.gelar_depan + " " + el.nama_lengkap;
          //   }
          //   if (el.gelar_belakang !== "-") {
          //     el.nama_lengkap = el.nama_lengkap + " " + el.gelar_belakang;
          //   }
          // });
          this.setState({
            daftarKonfirmasiEdit: data,
            tempdaftarKonfirmasiEdit: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarKonfirmasiEdit: null,
            tempdaftarKonfirmasiEdit: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({
      sortedInfo: null,
      searchText: "",
      daftarKonfirmasiEdit: this.state.tempdaftarKonfirmasiEdit,
    });
  };

  onChangeSearch = (evt) => {
    this.setState({ searchText: evt.target.value });
    if (this.state.tempdaftarKonfirmasiEdit) {
      if (evt.target.value === "") {
        this.setState({
          daftarKonfirmasiEdit: this.state.tempdaftarKonfirmasiEdit,
        });
      } else {
        this.setState({
          daftarKonfirmasiEdit: this.state.tempdaftarKonfirmasiEdit.filter(
            (i) => {
              return (
                i.nama_lengkap
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase()) ||
                i.institusi_asal
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase()) ||
                i.jenjang_akademik
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase()) ||
                i.provinsi_institusi
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase()) ||
                i.kota_institusi
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase())
              );
            }
          ),
        });
      }
    }
  };

  onTerima = (param) => {
    const user = this.context[0];
    myAxios
      .put(`confirmDosen/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        let filter = this.state.daftarKonfirmasiEdit.filter((el) => {
          return el.id !== param;
        });
        this.setState({
          daftarKonfirmasiEdit: filter,
          tempdaftarKonfirmasiEdit: filter,
        });

        message.success("Konfirmasi edit berhasil diterima!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onTolak = (param) => {
    const user = this.context[0];
    myAxios
      .put(`tolakDosen/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        this.getDataKonfirmasi();
        message.success("Konfirmasi edit berhasil di tolak!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onDetail = (index) => {
    window.location.pathname = `/tampilDetail/${index}`;
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: "7%",
      },
      {
        title: "Nama Anggota",
        dataIndex: "nama_lengkap",
        key: "nama_lengkap",
        filteredValue: filteredInfo.nama_lengkap || null,
        sorter: (a, b) => a.nama_lengkap.length - b.nama_lengkap.length,
        ellipsis: true,
      },
      {
        title: "Universitas",
        dataIndex: "institusi_asal",
        key: "institusi_asal",
        filteredValue: filteredInfo.institusi_asal || null,
        sorter: (a, b) => a.institusi_asal.length - b.institusi_asal.length,
        ellipsis: true,
      },
      {
        title: "Provinsi",
        dataIndex: "provinsi_institusi",
        key: "provinsi_institusi",
        filteredValue: filteredInfo.provinsi_institusi || null,
        sorter: (a, b) =>
          a.provinsi_institusi.length - b.provinsi_institusi.length,
        ellipsis: true,
      },
      {
        title: "Detail",
        dataIndex: "jenjang_akademik",
        key: "jenjang_akademik",
        filteredValue: filteredInfo.jenjang_akademik || null,
        sorter: (a, b) => a.jenjang_akademik.length - b.jenjang_akademik.length,
        ellipsis: true,
      },
      {
        align: "center",
        title: "Aksi",
        dataIndex: "id_dosen",
        key: "id",

        render: (dataIndex) => (
          <div className="aksiKonfirmasiEdit">
            {this.state.dataDetail && (
              <Modal
                width={500}
                title="Detail Calon Anggota"
                visible={this.state.visible}
                onCancel={() => this.setState({ visible: false })}
                footer={[]}
              >
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Nama Lengkap</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.nama_lengkap}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Tempat Lahir</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.tempat_lahir}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Tanggal Lahir</b>
                    </p>
                  </Col>
                  <Col md={12}>
                    : {moment(this.state.dataDetail.tanggal_lahir).format("LL")}
                  </Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Institusi Asal</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.institusi_asal}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Kota Institus</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.kota_institusi}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Provinsi Institusi</b>
                    </p>
                  </Col>
                  <Col md={12} style={{ textTransform: "capitalize" }}>
                    : {this.state.dataDetail.provinsi_institusi}
                  </Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Program Studi</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.prodi}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Jenjang Mengajar</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.jenjang}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Pendidikan Terakhir</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.pendidikan}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Jenjang Akademik</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.jenjang_akademik}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>NIDN</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.nidn}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Nomor Handphone</b>
                    </p>
                  </Col>
                  <Col md={12}>: {this.state.dataDetail.no_hp}</Col>
                </Row>
                <Row align="space-between">
                  <Col md={12}>
                    <p>
                      <b>Foto</b>
                    </p>
                  </Col>
                  <Col md={12}>
                    <Image
                      style={{
                        width: "75px",
                        height: "75px",
                        objectFit: "cover",
                      }}
                      src={`https://ikdkiapi.arkineliezer.com/public/images/${this.state.dataDetail.foto}`}
                    />
                  </Col>
                </Row>
              </Modal>
            )}
            <Row justify="center">
              <Col>
                <Tooltip
                  placement="bottom"
                  title="Lihat data"
                  color="#1f1f1f"
                  key="white"
                >
                  <Button
                    className="btn-info"
                    onClick={() => this.onDetail(dataIndex)}
                  >
                    <InfoLg />
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <div
        className="konfirmasiEdit"
        style={{
          padding: "25px 30px",
          margin: "25px 30px",
          backgroundColor: "white",
        }}
      >
        <h1
          style={{
            fontSize: "x-large",
            color: "#001529",
            textTransform: "uppercase",
          }}
        >
          <strong>Konfirmasi Edit Data Dosen</strong>
        </h1>
        <div
          style={{
            border: "1px solid #8C98AD",
            marginTop: "-10px",
            marginBottom: "15px",
          }}
        ></div>
        <Row style={{ marginBottom: 16 }} justify="space-between">
          <Col md={12}>
            <Button className="btn-hapus-filter" onClick={this.clearFilters}>
              Hapus Filter
            </Button>
          </Col>
          <Col md={12}>
            <Input
              value={this.state.searchText}
              style={{ width: "100%" }}
              placeholder="Cari data anggota disini"
              onChange={this.onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          columns={columns}
          scroll={{ x: 900, y: 1500 }}
          dataSource={this.state.daftarKonfirmasiEdit}
          onChange={this.handleChange}
          size="middle"
        />
      </div>
    );
  }
}

export default KonfirmasiEdit;
