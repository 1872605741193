import React from "react";
import myAxios from "../../../../myAxios";
import "./TabelJurnal.css";
import {
  Table,
  Button,
  Input,
  Tooltip,
  Popconfirm,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TrashFill } from "react-bootstrap-icons";
import { UserContext } from "../../../../context/UserContext.jsx";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class TabelJurnal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchedColumn: "",
      loading: false,
      daftarJurnal: null,
      tempdaftarJurnal: null,
      visible: false,
      dataDetail: null,
      loadingAct: false,
    };
  }
  static contextType = UserContext;
  componentDidMount() {
    if (this.state.daftarJurnal === null) {
      this.getJurnal();
    }
  }

  getJurnal = () => {
    const user = this.context[0];
    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`jurnalPublic`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          this.setState({
            daftarJurnal: data,
            tempdaftarJurnal: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarJurnal: null,
            tempdaftarJurnal: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearch = (evt) => {
    if (this.state.tempdaftarJurnal) {
      if (evt.target.value === "") {
        this.setState({ daftarJurnal: this.state.tempdaftarJurnal });
      } else {
        this.setState({
          daftarJurnal: this.state.tempdaftarJurnal.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.nama_jurnal
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.judul.toLowerCase().includes(evt.target.value.toLowerCase())
            );
          }),
        });
      }
    }
  };

  onDelete = (param) => {
    const user = this.context[0];
    this.setState({ loadingAct: true });
    myAxios
      .put(`deleteJurnalAdmin/${param}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        this.getJurnal();
        this.setState({ loadingAct: false });
        message.success("Data Publikasi berhasil di hapus!");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        this.setState({ loadingAct: false });
      });
  };

  onEditJurnal = (index) => {
    window.location.pathname = `/editJurnal/${index}`;
  };

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: "7%",
      },
      {
        title: "Nama Anggota",
        dataIndex: "nama_gelar",
        key: "nama_gelar",
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: "Judul Publikasi",
        dataIndex: "judul",
        key: "judul",
        filteredValue: filteredInfo.judul || null,
        sorter: (a, b) => a.judul.length - b.judul.length,
        ellipsis: true,
      },
      {
        title: "Nama Publikasi",
        dataIndex: "nama_jurnal",
        key: "nama_jurnal",
        filteredValue: filteredInfo.nama_jurnal || null,
        sorter: (a, b) => a.nama_jurnal.length - b.nama_jurnal.length,
        ellipsis: true,
      },
    ];
    return (
      <div
        className="jurnal"
        style={{
          padding: "25px 30px",
          margin: "25px 30px",
          backgroundColor: "white",
        }}
      >
        <h1
          style={{
            fontSize: "x-large",
            color: "#001529",
            textTransform: "uppercase",
          }}
        >
          <strong>Tabel Publikasi</strong>
        </h1>
        <div
          style={{
            border: "1px solid #8C98AD",
            marginTop: "-10px",
            marginBottom: "15px",
          }}
        ></div>
        <Row style={{ marginBottom: 16 }} justify="space-between">
          <Col md={12}>
            <Button className="btn-hapus-filter" onClick={this.clearFilters}>
              Hapus Filter
            </Button>
          </Col>
          <Col md={12}>
            <Input
              style={{ width: "100%" }}
              placeholder="Cari data publikasi disini"
              onChange={this.onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          scroll={{ x: 900, y: 1500 }}
          columns={columns}
          dataSource={this.state.daftarJurnal}
          onChange={this.handleChange}
          size="middle"
        />
      </div>
    );
  }
}

export default TabelJurnal;
