import React, { useState, useContext, useEffect } from "react";
import myAxios from "../../../myAxios";
import { UserContext } from "../../../context/UserContext";
import { useHistory, useParams } from "react-router-dom";
import { Form, Button, message, Row, Col } from "antd";
import moment from "moment";
import "./Detail.css";

const Detail = () => {
  const [user, setUser] = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [dataDetailAwal, setDataDetailAwal] = useState(null);
  const [dataDetailAkhir, setDataDetailAkhir] = useState(null);

  const { userId } = useParams();

  let history = useHistory();
  useEffect(() => {
    if (dataDetailAwal === null) {
      getDataAnggota();
    }
  });

  const getDataAnggota = () => {
    myAxios
      .get(`diffEdit/${userId}`, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        const data = res.data;

        setDataDetailAwal(data.dataAwal);
        setDataDetailAkhir(data.dataEdit);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Tidak Ditemukan") {
            message.error(err.response.data.message);
            history.push(`/anggotaIKDKI`);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };

  const onTerima = () => {
    setLoading(true);

    myAxios
      .put(`confirmEdit/${userId}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        setLoading(false);
        message.success("Konfirmasi edit berhasil diterima!");
        history.push("/konfirmasiEdit");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          setUser(null);
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        setLoading(false);
      });
  };

  const onTolak = () => {
    setLoading(true);
    myAxios
      .put(`rejectEdit/${userId}`, null, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        setLoading(false);
        message.success("Konfirmasi edit berhasil di tolak!");
        history.push("/konfirmasiEdit");
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          setUser(null);
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          message.error("Gagal menerima : " + err);
        }
        setLoading(false);
      });
  };

  return (
    <div
      className="editDetail"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
      }}
    >
      {dataDetailAwal && dataDetailAkhir && (
        <div>
          <Row>
            <Col lg={12} md={24} sm={24}>
              <Row>
                <Col>
                  <h1
                    style={{
                      fontSize: "x-large",
                      color: "#001529",
                      textTransform: "uppercase",
                    }}
                  >
                    <strong>Data Lama</strong>
                  </h1>
                </Col>
              </Row>
              <Row align="">
                <Col md={20}>
                  <div
                    style={{
                      border: "1px solid #8C98AD",
                      marginTop: "-10px",
                      marginBottom: "15px",
                    }}
                  ></div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Nama Lengkap</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.nama_lengkap}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Gelar Depan</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.gelar_depan}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Gelar Belakang</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.gelar_belakang}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Tempat Lahir</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.tempat_lahir}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Tanggal Lahir</b>
                  </p>
                </Col>
                <Col md={12}>
                  : {moment(dataDetailAwal.tanggal_lahir).format("LL")}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Institusi Asal</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.institusi_asal}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Kota Institusi</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.kota_institusi}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Provinsi Institusi</b>
                  </p>
                </Col>
                <Col md={12} style={{ textTransform: "capitalize" }}>
                  : {dataDetailAwal.provinsi_institusi}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Program Studi</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.prodi}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Jenjang Mengajar</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.jenjang}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Pendidikan Terakhir</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.pendidikan}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Jenjang Akademik</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.jenjang_akademik}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Bidang Keahlian 1</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.keahlian_1}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Bidang Keahlian 2</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.keahlian_2}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Bidang Keahlian 3</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.keahlian_3}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>NIDN</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.nidn}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Nomor Handphone</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAwal.no_hp}</Col>
              </Row>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <Row>
                <Col>
                  <h1
                    style={{
                      fontSize: "x-large",
                      color: "#001529",
                      textTransform: "uppercase",
                    }}
                  >
                    <strong>Data Baru</strong>
                  </h1>
                </Col>
              </Row>
              <Row>
                <Col md={20}>
                  <div
                    style={{
                      border: "1px solid #8C98AD",
                      marginTop: "-10px",
                      marginBottom: "15px",
                    }}
                  ></div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Nama Lengkap</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.nama_lengkap}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Gelar Depan</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.gelar_depan}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Gelar Belakang</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.gelar_belakang}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Tempat Lahir</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.tempat_lahir}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Tanggal Lahir</b>
                  </p>
                </Col>
                <Col md={12}>
                  : {moment(dataDetailAkhir.tanggal_lahir).format("LL")}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Institusi Asal</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.institusi_asal}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Kota Institusi</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.kota_institusi}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Provinsi Institusi</b>
                  </p>
                </Col>
                <Col md={12} style={{ textTransform: "capitalize" }}>
                  : {dataDetailAkhir.provinsi_institusi}
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Program Studi</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.prodi}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Jenjang Mengajar</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.jenjang}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Pendidikan Terakhir</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.pendidikan}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Jenjang Akademik</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.jenjang_akademik}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Bidang Keahlian 1</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.keahlian_1}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Bidang Keahlian 2</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.keahlian_2}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Bidang Keahlian 3</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.keahlian_3}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>NIDN</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.nidn}</Col>
              </Row>
              <Row>
                <Col md={12}>
                  <p>
                    <b>Nomor Handphone</b>
                  </p>
                </Col>
                <Col md={12}>: {dataDetailAkhir.no_hp}</Col>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col>
              <Button
                danger
                style={{ marginRight: "5px" }}
                onClick={onTolak}
                loading={loading}
              >
                Tolak
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={onTerima} loading={loading}>
                Konfirmasi
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Detail;
