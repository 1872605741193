import React from "react";
import { useState, useContext, useEffect } from "react";
import myAxios from "../../../myAxios";
import "./Pencarian.css";
import {
  Row,
  Col,
  message,
  Spin,
  Form,
  Input,
  Button,
  Table,
  Tooltip,
  Modal,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/UserContext";
import { Folder2Open } from "react-bootstrap-icons";
import Empty from "../../../assets/img/empty.svg";
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
const Pencarian = () => {
  const [user, setUser] = useContext(UserContext);
  const [hasilPencarian, setPencarian] = useState([]);
  const [key, setKey] = useState("");
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {});

  const onCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    setLoading(true);
    let newObj = {
      kata_kunci: values.kata_kunci,
    };
    setKey(values.kata_kunci);
    myAxios
      .post(`searchDosen`, newObj, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data.data);
        setPencarian(res.data.data);
      })
      .catch((err) => {
        if (err.response.data.message === "Unauthenticated.") {
          message.error("Sesi anda telah habis!");
          localStorage.removeItem("user");
          window.location.pathname = "/login";
        } else {
          if (err.response.data.message === "Data Tidak Ditemukan") {
            message.error(err.response.data.message);
          } else {
            message.error(err.response.data.message);
          }
        }
        setLoading(false);
      });
  };
  const onFinishFailed = () => {};
  const OnDetail = (row) => {
    setVisible(true);
    setDetail(row);
    console.log(row);
  };

  const getHighlightedText = (text, highlight) => {
    if (text) {
      const parts = text.split(new RegExp(`(${highlight})`, "gi"));
      return (
        <span>
          {parts.map((part, i) => (
            <span
              key={i}
              style={
                part.toLowerCase() === highlight.toLowerCase()
                  ? { backgroundColor: "yellow" }
                  : {}
              }
            >
              {part}
            </span>
          ))}{" "}
        </span>
      );
    }
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      sorter: (a, b) => a.no - b.no,

      width: "5%",
    },
    {
      title: "Nama Anggota",
      dataIndex: "nama_lengkap",
      key: "nama_lengkap",
      sorter: (a, b) => a.nama_lengkap.length - b.nama_lengkap.length,
    },
    {
      title: "Keahlian 1",
      dataIndex: "keahlian_1",
      key: "keahlian_1",
      sorter: (a, b) => a.keahlian_1.length - b.keahlian_1.length,

      render: (text) => <>{getHighlightedText(text, key)}</>,
    },
    {
      title: "Keahlian 2",
      dataIndex: "keahlian_2",
      key: "keahlian_2",
      sorter: (a, b) => a.keahlian_2.length - b.keahlian_2.length,

      render: (text) => <>{getHighlightedText(text, key)}</>,
    },
    {
      title: "Keahlian 3",
      dataIndex: "keahlian_3",
      key: "keahlian_3",
      sorter: (a, b) => a.keahlian_3.length - b.keahlian_3.length,

      render: (text) => <>{getHighlightedText(text, key)}</>,
    },

    {
      align: "center",
      title: "Aksi",
      dataIndex: "id",
      key: "id",

      render: (dataIndex, row) => (
        <div className="aksiPencarian">
          <Tooltip
            placement="bottom"
            title="Lihat Detail"
            color="#1f1f1f"
            key="white"
          >
            <Button className="btn-info" onClick={() => OnDetail(row)}>
              <Folder2Open />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div
      className="calonAnggota"
      style={{
        padding: "25px 30px",
        margin: "25px 30px",
        backgroundColor: "white",
        minHeight: "55vh",
      }}
    >
      <h1
        style={{
          fontSize: "x-large",
          color: "#001529",
          textTransform: "uppercase",
        }}
      >
        <strong>Pencarian </strong>
      </h1>
      <div
        style={{
          border: "1px solid #8C98AD",
          marginTop: "-10px",
          marginBottom: "15px",
        }}
      ></div>
      <Form
        layout="vertical"
        form={form}
        name="basic"
        initialValues={{ remember: false }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row justify="space-between">
          <Col md={22} xs={15}>
            <Form.Item
              labelAlign="left"
              name="kata_kunci"
              rules={[
                {
                  required: true,
                  message: "Bagian ini wajib diisi",
                },
              ]}
            >
              <Input placeholder="Cari kompetensi anggota disini" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                style={{ width: "fit-content", width: "100%" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Cari
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {loading === true ? (
        <h1 className="loading">
          <Spin indicator={antIcon} />
          <p>Mencari data..</p>
        </h1>
      ) : hasilPencarian.length > 0 ? (
        <>
          <Table
            loading={loading}
            loadingIndicator={antIcon}
            scroll={{ x: 900, y: 1500 }}
            columns={columns}
            dataSource={hasilPencarian}
            // onChange={this.handleChange}
            size="middle"
          />
        </>
      ) : (
        <>
          <img
            alt="empty.svg"
            src={Empty}
            style={{
              width: "200px",
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "20px",
            }}
          />
          <p
            style={{
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            Tidak Ada Data
          </p>
        </>
      )}
      {detail && (
        <Modal
          title="Detail Dosen"
          visible={visible}
          onCancel={onCancel}
          footer={[]}
        >
          <Row justify="start">
            <Col md={8} xs={8}>
              Nama Anggota
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.nama_lengkap}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Nomor
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.no_anggota}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Email
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.email}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              HP
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.no_hp}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Tempat Lahir
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.tempat_lahir}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Tanggal Lahir
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.tanggal_lahir}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Institusi
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.institusi_asal}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Kota Institusi
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.kota_institusi}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Provinsi Institusi
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.provinsi_institusi}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Jenjang Mengajar
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.jenjang}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Jenjang Akademik
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.jenjang_akademik}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Pendidikan
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.pendidikan}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Prodi
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {detail.prodi}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Keahlian 1
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {getHighlightedText(detail.keahlian_1, key)}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Keahlian 2
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {getHighlightedText(detail.keahlian_2, key)}
            </Col>
          </Row>

          <Row justify="start">
            <Col md={8} xs={8}>
              Keahlian 3
            </Col>
            <Col md={1} xs={1}>
              :
            </Col>
            <Col md={15} xs={15}>
              {getHighlightedText(detail.keahlian_3, key)}
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
};

export default Pencarian;
