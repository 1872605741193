import React from 'react';
import myAxios from '../../myAxios';
import ReactToPrint from 'react-to-print';
import { UserContext } from '../../context/UserContext.jsx';
import {
  Table,
  Button,
  Input,
  Tooltip,
  Row,
  Col,
  message,
  Spin,
  Modal,
  Popconfirm,
  Space,
  Tag,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Calendar2CheckFill,
  PencilFill,
  PersonBadgeFill,
} from 'react-bootstrap-icons';
import './AnggotaIKDKI.css';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const tableLoading = {
  indicator: <Spin indicator={antIcon} />,
};
class AnggotaIKDKI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      searchText: '',
      searchedColumn: '',
      loading: false,
      loadingAct: false,
      daftarAnggotaIKDKI: null,
      tempdaftarAnggotaIKDKI: null,
      visible: false,
      dataKartu: null,
      loadingUnduh: false,
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    if (this.state.daftarAnggotaIKDKI === null) {
      this.getDataAnggota();
    }
  }

  getDataAnggota = () => {
    const user = this.context[0];

    this.setState({
      loading: tableLoading,
    });
    myAxios
      .get(`dosenAdmin`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        const data = res.data.data;
        if (data !== null) {
          this.setState({
            daftarAnggotaIKDKI: data,
            tempdaftarAnggotaIKDKI: data,
            loading: false,
          });
        } else {
          this.setState({
            daftarAnggotaIKDKI: null,
            tempdaftarAnggotaIKDKI: null,
            loading: false,
          });
          message.info(res.data.message);
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          message.error(err.response.data.message);
        }
        this.setState({
          loading: false,
        });
      });
  };

  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  clearFilters = () => {
    this.setState({
      filteredInfo: null,
      searchText: '',
      daftarAnggotaIKDKI: this.state.tempdaftarAnggotaIKDKI,
    });
  };

  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  onChangeSearch = (evt) => {
    this.setState({ searchText: evt.target.value });
    if (this.state.tempdaftarAnggotaIKDKI) {
      if (evt.target.value === '') {
        this.setState({
          daftarAnggotaIKDKI: this.state.tempdaftarAnggotaIKDKI,
        });
      } else {
        this.setState({
          daftarAnggotaIKDKI: this.state.tempdaftarAnggotaIKDKI.filter((i) => {
            return (
              i.nama_gelar
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.institusi_asal
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.provinsi_institusi
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              i.no_anggota
                .toLowerCase()
                .includes(evt.target.value.toLowerCase()) ||
              (i.keahlian_1 &&
                i.keahlian_1
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase())) ||
              (i.keahlian_2 &&
                i.keahlian_2
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase())) ||
              (i.keahlian_3 &&
                i.keahlian_3
                  .toLowerCase()
                  .includes(evt.target.value.toLowerCase()))
            );
          }),
        });
      }
    }
  };

  onEditAnggota = (index) => {
    this.setState({
      loadingAct: true,
    });
    window.location.pathname = `/editAnggota/${index}`;
  };

  onCetakKartu = (index) => {
    this.setState({
      loadingAct: true,
    });
    let filter = this.state.daftarAnggotaIKDKI.filter((el) => {
      return el.id === index;
    });
    let data = filter[0];

    this.setState({ visible: true });
    this.setState({
      dataKartu: data,
    });
    this.setState({
      loadingAct: false,
    });
  };

  onSubmitUnduh = () => {
    this.setState({ visible: false });
    message.success('Kartu berhasil dicetak');
  };

  onPerpanjang = (id) => {
    const user = this.context[0];
    this.setState({
      loadingAct: true,
    });
    myAxios
      .get(`perpanjang/${id}`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        this.setState({
          loadingAct: false,
        });
        this.getDataAnggota();
        this.setState({ searchText: null });
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          if (err.response.data.message === 'Data Tidak Ditemukan') {
            message.error(err.response.data.message);
          } else {
            message.error(err.response.data.message);
          }
        }
        this.setState({
          loadingAct: false,
        });
      });
  };

  onUnduhData = () => {
    const user = this.context[0];
    this.setState({ loadingUnduh: true });
    myAxios
      .get(`exportDosen`, {
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Data Dosen.xlsx`); //or any other extension
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        this.setState({ loadingUnduh: false });
        message.success('Unduh Data Dosen Berhasil!');
      })
      .catch((err) => {
        if (err.response.data.message === 'Unauthenticated.') {
          message.error('Sesi anda telah habis!');
          localStorage.removeItem('user');
          window.location.pathname = '/login';
        } else {
          if (err.response.data.message === 'Data Tidak Ditemukan') {
            message.error(err.response.data.message);
          } else {
            message.error(err.response.data.message);
          }
        }
        this.setState({
          loadingAct: false,
        });
      });
  };

  render() {
    const user = this.context[0];
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: '7%',
      },
      {
        title: 'Nama Anggota',
        dataIndex: 'nama_gelar',
        key: 'nama_gelar',
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: 'Universitas',
        dataIndex: 'institusi_asal',
        key: 'institusi_asal',
        filteredValue: filteredInfo.institusi_asal || null,
        sorter: (a, b) => a.institusi_asal.length - b.institusi_asal.length,
        ellipsis: true,
      },
      {
        title: 'Provinsi',
        dataIndex: 'provinsi_institusi',
        key: 'provinsi_institusi',
        filteredValue: filteredInfo.provinsi_institusi || null,
        sorter: (a, b) =>
          a.provinsi_institusi.length - b.provinsi_institusi.length,
        ellipsis: true,
      },
      {
        title: 'No. Anggota',
        dataIndex: 'no_anggota',
        key: 'no_anggota',
        filteredValue: filteredInfo.no_anggota || null,
        sorter: (a, b) => a.no_anggota.length - b.no_anggota.length,
        ellipsis: true,
      },
      {
        title: 'Keahlian',
        dataIndex: 'id',
        key: 'id',
        render: (dataIndex, row) => (
          <>{`${row.keahlian_1}, ${row.keahlian_2 ? row.keahlian_2:''}, ${row.keahlian_3}`}</>
        ),
      },

      {
        align: 'center',
        title: 'Aksi',
        dataIndex: 'id',
        key: 'id',

        render: (dataIndex) => (
          <div>
            <div className='aksiAnggota'>
              {this.state.loadingAct && <Spin indicator={antIcon} />}
              {!this.state.loadingAct && (
                <Row justify='space-around'>
                  <Col></Col>
                  <Col>
                    <Tooltip
                      placement='bottom'
                      title='Edit anggota'
                      color='#1f1f1f'
                      key='white'>
                      <Button
                        className='btn-edit'
                        onClick={() => this.onEditAnggota(dataIndex)}>
                        <PencilFill />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip
                      placement='bottom'
                      title='Cetak kartu'
                      color='#1f1f1f'
                      key='white'>
                      <Button
                        className='btn-cetak-kartu'
                        onClick={() => this.onCetakKartu(dataIndex)}>
                        <PersonBadgeFill />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Popconfirm
                      placement='bottom'
                      title='Perpanjang keanggotaan ?'
                      onConfirm={() => this.onPerpanjang(dataIndex)}
                      okText='Perpanjang'
                      cancelText='Batal'>
                      <Tooltip
                        placement='bottom'
                        title='Perpanjang Keanggotaan'
                        color='#1f1f1f'
                        key='white'>
                        <Button className='btn-perpanjang-kartu'>
                          <Calendar2CheckFill />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                  <Col></Col>
                </Row>
              )}
            </div>
          </div>
        ),
      },
    ];
    const columnsSuperAdmin = [
      {
        title: 'No',
        dataIndex: 'no',
        key: 'no',
        filteredValue: filteredInfo.no || null,
        sorter: (a, b) => a.no - b.no,
        ellipsis: true,
        width: '7%',
      },
      {
        title: 'Nama Anggota',
        dataIndex: 'nama_gelar',
        key: 'nama_gelar',
        filteredValue: filteredInfo.nama_gelar || null,
        sorter: (a, b) => a.nama_gelar.length - b.nama_gelar.length,
        ellipsis: true,
      },
      {
        title: 'Universitas',
        dataIndex: 'institusi_asal',
        key: 'institusi_asal',
        filteredValue: filteredInfo.institusi_asal || null,
        sorter: (a, b) => a.institusi_asal.length - b.institusi_asal.length,
        ellipsis: true,
      },
      {
        title: 'Provinsi',
        dataIndex: 'provinsi_institusi',
        key: 'provinsi_institusi',
        filteredValue: filteredInfo.provinsi_institusi || null,
        sorter: (a, b) =>
          a.provinsi_institusi.length - b.provinsi_institusi.length,
        ellipsis: true,
      },
      {
        title: 'No. Anggota',
        dataIndex: 'no_anggota',
        key: 'no_anggota',
        filteredValue: filteredInfo.no_anggota || null,
        sorter: (a, b) => a.no_anggota.length - b.no_anggota.length,
        ellipsis: true,
      },
      {
        title: 'Keahlian',
        dataIndex: 'id',
        key: 'id',
        render: (dataIndex, row) => (
          <>{`${row.keahlian_1}, ${row.keahlian_2}, ${row.keahlian_3}`}</>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filteredValue: filteredInfo.status || null,
        sorter: (a, b) => a.status.length - b.status.length,
        ellipsis: true,
        render: (text) => {
          return <Tag color={text === 'Aktif' ? 'green' : 'red'}>{text}</Tag>;
        },
      },

      {
        align: 'center',
        title: 'Aksi',
        dataIndex: 'id',
        key: 'id',

        render: (dataIndex) => (
          <div>
            <div className='aksiAnggota'>
              {this.state.loadingAct && <Spin indicator={antIcon} />}
              {!this.state.loadingAct && (
                <Row justify='space-around'>
                  <Col></Col>
                  <Col>
                    <Tooltip
                      placement='bottom'
                      title='Edit anggota'
                      color='#1f1f1f'
                      key='white'>
                      <Button
                        className='btn-edit'
                        onClick={() => this.onEditAnggota(dataIndex)}>
                        <PencilFill />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip
                      placement='bottom'
                      title='Cetak kartu'
                      color='#1f1f1f'
                      key='white'>
                      <Button
                        className='btn-cetak-kartu'
                        onClick={() => this.onCetakKartu(dataIndex)}>
                        <PersonBadgeFill />
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col>
                    <Popconfirm
                      placement='bottom'
                      title='Perpanjang keanggotaan ?'
                      onConfirm={() => this.onPerpanjang(dataIndex)}
                      okText='Perpanjang'
                      cancelText='Batal'>
                      <Tooltip
                        placement='bottom'
                        title='Perpanjang Keanggotaan'
                        color='#1f1f1f'
                        key='white'>
                        <Button className='btn-perpanjang-kartu'>
                          <Calendar2CheckFill />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </Col>
                  <Col></Col>
                </Row>
              )}
            </div>
          </div>
        ),
      },
    ];
    return (
      <div
        className='lihatAdmin'
        style={{
          padding: '25px 30px',
          margin: '25px 30px',
          backgroundColor: 'white',
        }}>
        <h1
          style={{
            fontSize: 'x-large',
            color: '#001529',
            textTransform: 'uppercase',
          }}>
          <strong>Anggota IKDKI</strong>
        </h1>
        <div
          style={{
            border: '1px solid #8C98AD',
            marginTop: '-10px',
            marginBottom: '15px',
          }}></div>
        <Row style={{ marginBottom: 16 }} justify='space-between'>
          <Col md={12}>
            <Space>
              <Button className='btn-hapus-filter' onClick={this.clearFilters}>
                Hapus Filter
              </Button>
              {user.role === 'Super Admin' && (
                <Button
                  className='btn-hapus-filter'
                  onClick={this.onUnduhData}
                  loading={this.state.loadingUnduh}>
                  Unduh Data
                </Button>
              )}
            </Space>
          </Col>
          <Col md={12}>
            <Input
              value={this.state.searchText}
              style={{ width: '100%' }}
              placeholder='Cari data anggota disini'
              onChange={this.onChangeSearch}
            />
          </Col>
        </Row>
        <Table
          loading={this.state.loading}
          loadingIndicator={antIcon}
          columns={user.role === 'Super Admin' ? columnsSuperAdmin : columns}
          scroll={{ x: 900, y: 1500 }}
          dataSource={this.state.daftarAnggotaIKDKI}
          onChange={this.handleChange}
          size='middle'
        />
        {this.state.dataKartu && (
          <Modal
            width={700}
            title='Cetak Kartu Anggota'
            visible={this.state.visible}
            onCancel={() => this.setState({ visible: false })}
            footer={[]}>
            <div
              className='container-kartu'
              ref={(el) => (this.componentRef = el)}>
              <Row justify='center' align='middle' style={{ height: '100%' }}>
                <Col style={{ margin: '0 5px' }}>
                  <div className='kartuAnggota'>
                    <div className='kerangka-foto'>
                      <img
                        alt='foto.png'
                        src={`https://api-ikdki.arkin-dev.com/public/images/${this.state.dataKartu.foto}`}></img>
                      <div className='wrapper-text'>
                        <p className='namaLengkap'>
                          {this.state.dataKartu.nama_gelar}
                        </p>
                        <p className='noAnggota'>
                          {this.state.dataKartu.no_anggota}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col style={{ margin: '0 5px' }}>
                  <div className='kartuAnggotaBack'>
                    <p className='tanggal-berlaku'>
                      {this.state.dataKartu.tahun_kartu}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <Row justify='center' className='btn-cetak-card'>
              <Col>
                <ReactToPrint
                  onAfterPrint={this.onSubmitUnduh}
                  documentTitle={`KartuAnggota_${this.state.dataKartu.no_anggota}`}
                  trigger={() => {
                    return (
                      <Button
                        loading={this.state.loading}
                        type='primary'
                        onClick={this.onCetakCard}>
                        Unduh Kartu
                      </Button>
                    );
                  }}
                  content={() => this.componentRef}
                />
              </Col>
            </Row>
          </Modal>
        )}
      </div>
    );
  }
}

export default AnggotaIKDKI;
